import {Box, Button, IconButton, SxProps} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useTranslation} from "react-i18next";
import {AddLocation as AddLocationIcon} from "../icons/add-location";
import React from "react";
import {t} from "i18next";
import { IProcessing } from "../core";

export interface PanelButtonProps {
    children?: React.ReactNode,
    onClick: () => void,
    variant?: 'text' | 'contained' | 'outlined',
    style?: React.CSSProperties;
}

export const PanelButton = ({children, onClick, variant, style}: PanelButtonProps) => {
    return (
        <Button
            variant={variant || 'text'}
            onClick={onClick}
            style={style}
            sx={{
                ":root": {
                    padding: 6,
                    minWidth: 'auto'
                }
            }}
        >
            {children}
        </Button>
    );
};

export interface MenuButtonProps {
    onClick: (element: Element) => void;
}

export const MenuButton = ({onClick}: MenuButtonProps) => {

    return (
        <IconButton
            color="secondary"
            size="small"
            onClick={(event) => onClick(event.currentTarget)}
        >
            <MoreVertIcon/>
        </IconButton>
    );
};

export interface ButtonProps {
    onClick: () => void;
    disabled?: boolean;
    testid?: string;
    title?: string;
    style?: React.CSSProperties
}

export const AddButton = ({onClick, testid, title, disabled, style}: ButtonProps) => {
    const {t} = useTranslation();
    return (
        <Button
            data-testid={testid}
            onClick={onClick}
            size="medium"
            style={style}
            variant="contained"
            disabled={disabled}
            sx={{
                height: 40,
                width: 120
            }}
        >
            {title ?? t('buttons.create')}
        </Button>
    )
}

export const AddLocalityButton = ({onClick, testid}: ButtonProps) => {
    const {t} = useTranslation();
    return (
        <Button
            data-testid={testid}
            variant="outlined"
            size="small"
            startIcon={(
                <AddLocationIcon fontSize="small" color="secondary"/>
            )}
            onClick={onClick}
            sx={{
                height: 40, width: 120
            }}
        >
            {t('common.Address')}
        </Button>
    )
}

export const ResetFiltersButton = ({onClick, testid}: ButtonProps) => {
    const {t} = useTranslation();
    return (
        <Button
            data-testid={testid}
            sx={{height: 40, width: 150}}
            size="small"
            color="error"
            onClick={onClick}
        >
            {t('common.resetFilters')}
        </Button>
    )
}

export interface ParamsButtonGroupProps {
    onCancel: () => void;
    onSave: () => void | Promise<void>;
    isCancelBtnDisabled?: boolean;
    isSaveBtnDisabled?: boolean;
    testid?: string;
    processing?: IProcessing
}


export const ParamsButtonGroup = ({
                                      onCancel,
                                      onSave,
                                      isSaveBtnDisabled,
                                      isCancelBtnDisabled,
                                      testid,
                                      processing
                                  }: ParamsButtonGroupProps) => {
    const {t} = useTranslation();
    return (

        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Button
                data-testid={`${testid}-save-btn`}
                onClick={onSave}
                sx={{m: 1, ml: 0, height: 40, width: 120}}
                type="submit"
                variant="contained"
                disabled={isSaveBtnDisabled}
            >
                {processing?.processing ? t('buttons.updated') : t('buttons.save')}
            </Button>
            <Button
                data-testid={`${testid}-cancel-btn`}
                onClick={onCancel}
                sx={{m: 1, height: 40, width: 120}}
                variant="outlined"
                disabled={isCancelBtnDisabled}
            >
                {t('buttons.cancel')}
            </Button>
        </Box>
    )
}

export const CreateButtonsGroup = ({
                                       onCancel,
                                       onSave,
                                       isSaveBtnDisabled,
                                       isCancelBtnDisabled,
                                       testid
                                   }: ParamsButtonGroupProps) => {
    const {t} = useTranslation();
    return (
        <Box
            sx={{
                display: 'flex',
            }}
        >
            <Button
                data-testid={`${testid}-save-btn`}
                onClick={onSave}
                sx={{m: 1, ml: 0, height: 40, width: 120}}
                type="submit"
                variant="contained"
                disabled={isSaveBtnDisabled}
            >
                {t('buttons.save')}
            </Button>
            <Button
                data-testid={`${testid}-cancel-btn`}
                onClick={onCancel}
                sx={{m: 1, height: 40, width: 120}}
                variant="outlined"
                disabled={isCancelBtnDisabled}
            >
                {t('buttons.cancel')}
            </Button>
        </Box>
    )
}


export const DeleteButton = ({onClick, disabled, testid}: ButtonProps) => {
    const {t} = useTranslation();
    return (
        <Button
            data-testid={`${testid}-delete-btn`}
            onClick={onClick}
            color="error"
            disabled={disabled}
            sx={{
                ml: 'auto',
                height: 40,
                width: 120
            }}
        >
            {t('buttons.delete')}
        </Button>
    )
}

export interface SimpleButtonProps {
    onClick: () => void;
    title: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    endIcon?: React.ReactNode;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    variant?: 'text' | 'outlined' | 'contained',
    testid?: string,
    sx?: SxProps,
}

export const SimpleButton = ({onClick, title, disabled, style, sx, endIcon, color, variant, testid}: SimpleButtonProps) => {
    return (
        <Button
            data-testid={`${testid}-btn`}
            onClick={onClick}
            variant={variant ? variant : "contained"}
            disabled={disabled}
            style={style}
            color={color ? color : 'primary'}
            sx={{
                height: 40,
                m: 0,
                width: 120,
                cursor: 'pointer',
                ...sx
            }}
            endIcon={endIcon}
        >
            {title}
        </Button>
    )
}
