import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, FormRoot, FormRow, ParamsButtonGroup, TextInput} from "../../../components";
import {DefaultValue} from "../../../components/shared/components/DefaultValue";
import {AllPermissions} from "../../../core/const";
import {ClientDetailsStore} from "../../../stores/management/clients/ClientDetailsStore";
import {callTheProcessingFunc} from "../../../stores";

interface Props {
    store: ClientDetailsStore;
    style?: React.CSSProperties;
}

export const ClientParametersTab = observer(({store, style}: Props) => {
    const {t} = useTranslation();

    return (
        <FormRoot style={style}>
            <DefaultValue
                testid="clientDetails-name"
                style={{marginTop: 10, marginBottom: 10}}
                store={store.details.fields.name}
                title={t('clientPage.name')}
            />
            <DefaultValue
                testid="clientDetails-clientName"
                style={{marginTop: 10, marginBottom: 10}}
                store={store.details.fields.clientName}
                title={t('clientPage.orgName')}
            />
            <DefaultValue
                testid="clientDetails-secretKey"
                style={{marginTop: 10, marginBottom: 10}}
                store={store.details.fields.secretKey}
                title={t('clientPage.secretKey')}
            />

            <FormRow style={{height: "max-content", marginTop: 10, marginBottom: 10}}>
                <TextInput
                    data-testid="clientDetails-description-input"
                    sx={{
                        width: '100%',
                        "&.MuiInputBase-root": {
                            height: '100%'
                        }
                    }}
                    label={t('common.description')}
                    value={store.details.fields.description.value}
                    onChange={t => store.details.fields.description.value = t}
                    outlined={true}
                    multiline
                />
            </FormRow>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',

                }}
            >

                <AuthorizePermission permissions={AllPermissions.client.update}>
                    <ParamsButtonGroup
                        testid="clientDetails"
                        onCancel={() => store.details.reset()}
                        onSave={async () => callTheProcessingFunc(store, () => store.submit(), (e) => store._store.updateState())()}
                        isCancelBtnDisabled={store.processing || store.details.equals}
                        isSaveBtnDisabled={store.processing || store.details.equals || !store.details.valid}
                    />
                </AuthorizePermission>
            </Box>
        </FormRoot>
    );
});
