import {observer} from "mobx-react";
import {ConfirmationForm, ExpertDialog, FormRoot, SimpleButton, ToolDivider} from "../../../components";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    IconButton,
    List,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {RequestDetailsStore} from "../../../stores/management/dcr/requests/RequestDetailsStore";
import {Organization} from "./forms/Organization";
import {OrganizationStore} from "../../../stores/management/dcr/requests/specifications/entity/OrganizationStore";
import {PersonStore} from "../../../stores/management/dcr/requests/specifications/entity/PersonStore";
import {Person} from "./forms/Person";
import {RequestStatusEnum, RequestTypeEnum} from "../../../stores/management/dcr/types";
import {DefaultValue} from "../../../components/shared/components/DefaultValue";
import {DefaultSelect} from "../../../components/shared/components/DefaultSelect";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import {X as XIcon} from "../../../icons/x";
import {DcrListItem} from "../DcrListItem";
import {ArrowLeft as ArrowLeftIcon} from '../../../icons/arrow-left'
import {DefaultCheckbox} from "../../../components/shared/components/DefaultCheckbox";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MessageIcon from '@mui/icons-material/Message';
import DownloadIcon from '@mui/icons-material/Download';
import {PersonExternalFirstValues} from "./forms/PersonExternalFirstValues";
import {OrganizationExternalFirstValues} from "./forms/OrganizationExternalFirstValues";
import {FieldNameValuesComponent} from "./forms/FieldNameValuesComponent";
import {useAuthorizationStore} from "../../../stores";

interface Props {
    store: RequestDetailsStore;
    style?: React.CSSProperties;
    onClose?: () => void;
    isOpenInDrawer: boolean;
}

export const RequestDetails = styled(observer(({store, style, onClose, isOpenInDrawer}: Props) => {
    const {isAdmin} = useAuthorizationStore();
    const {t} = useTranslation();
    const boxStyleRead = {marginRight: 30, minWidth: '250px', width: '20px'}
    const valueStyle = {overflow: 'hidden', textOverflow: 'ellipsis'}

    return (
        <>
            <ExpertDialog
                title={`${t("buttons.delete")}`}
                open={store.deleteDialogState}
                onClose={() => store.deleteDialogState = false}
            >
                <ConfirmationForm
                    disabled={false}
                    onSubmit={async () => await store._store.deleteRequest()}
                    onClose={() => store.deleteDialogState = false}
                >
                    <>
                        {t('common.deleteConfirmDcr').replace("{id}", store.dto.id)}
                    </>
                </ConfirmationForm>
            </ExpertDialog>
            {isOpenInDrawer && <Box
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {store._store.dto.id}
                </Typography>
                <IconButton
                    color="inherit"
                    onClick={onClose}
                >

                    <XIcon fontSize="small"/>
                </IconButton>
            </Box>
            }

            {
                store._store.takeUnTake &&
                <Box
                    sx={{
                        alignItems: 'center',
                        backgroundColor: (theme) => theme.palette.mode === 'dark'
                            ? 'neutral.800'
                            : 'neutral.100',
                        borderRadius: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        px: 3,
                        py: 2.5,
                        m: 3
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            m: -1,
                            '& > button': {
                                m: 1
                            }
                        }}
                    >
                        {
                            store._store.takeUnTake &&
                            <Tooltip title={t("dcrPage.checkCorrect")} placement="top">
                                <IconButton color="primary"
                                            style={{marginRight: 5}}
                                            component="label"
                                            onClick={async () => await store._store.reloadCorrect()}>
                                    <AutorenewIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            (store.dto.status === RequestStatusEnum.WaitingVerification || store.dto.status === RequestStatusEnum.OnVerification) &&
                            <SimpleButton
                                onClick={async () => {
                                    if (store._store.isTake) {
                                        await store._store.unpin();
                                    } else {
                                        await store._store.take();
                                    }
                                }}
                                title={t(store._store.isTake ? 'dcrPage.requestTable.untake' : 'dcrPage.requestTable.take')}
                                endIcon={store._store.isTake ? <ArrowLeftIcon fontSize="small"/> : <></>}
                            />
                        }
                        {store.specification.DetailsButtonsComponent}
                        {
                            <SimpleButton
                                onClick={async () => store._store._store.commentModel.openModal(store._store)}
                                title={t('dcrPage.comments')}
                                color="secondary"
                                style={{width: 165}}
                                endIcon={<IconButton color="inherit" size='small'>
                                    <span style={{fontSize: 16, paddingRight: 5}}>{store.dto.commentCount}</span>
                                    <MessageIcon fontSize="small"/>
                                </IconButton>}
                            />
                        }
                        {
                            !store._store.isAz &&
                            <Tooltip title={t('dcrPage.completeData')} placement="bottom">
                                <SimpleButton
                                    onClick={async () => await store._store.downloadMissedFieldsData()}
                                    title={t('dcrPage.complete')}
                                    color="secondary"
                                    disabled={store._store.hasEmptyFirstValues}
                                    style={{width: 165}}
                                    endIcon={<IconButton color="inherit" size='small'>
                                        <DownloadIcon fontSize="small"/>
                                    </IconButton>}
                                />
                            </Tooltip>
                        }
                        {(isAdmin && store.isTake) &&
                            <Tooltip title={t('dcrPage.completeData')} placement="bottom">
                                <SimpleButton
                                    onClick={async () => store.deleteDialogState = true}
                                    title={t('dcrPage.delete')}
                                    color="error"
                                    style={{width: 120}}
                                />
                            </Tooltip>
                        }

                    </Box>
                </Box>

            }
            <ExpertDialog
                title={t(store.declineOpen ? 'dcrPage.comment' : 'common.verification')}
                open={store.declineOpen}
                onClose={() => {
                    store.declineOpen = false
                    store.commentStore.value = ""
                }}
            >
                <ConfirmationForm
                    disabled={store.loading || store.commentStore.value == ""}
                    processing={store.loading}
                    submitButton={t('common.send')}
                    onSubmit={async () => await store.submit(RequestStatusEnum.Decline)}
                    onClose={() => {
                        store.declineOpen = false
                        store.commentStore.value = ""
                    }}>
                    <DefaultValue store={store.commentStore}
                                  testid="RequestDetails-commentStore"
                                  title={""}
                                  multiline
                                  InputProps={{
                                      sx: {
                                          height: '100%'
                                      }
                                  }}
                                  rows={10}
                                  style={{width: '100%'}}/>
                </ConfirmationForm>
            </ExpertDialog>
            <Box style={{
                height: '100%',
                overflow: 'scroll',
                paddingBottom: 80,
                position: 'relative'
            }}>
                {store.specification.DetailsBeforeComponent}
                <FormRoot style={style}>
                    <div>
                        {
                            store.visibilityDbData &&
                            <div style={{marginBottom: 15, marginLeft: 20}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{fontWeight: 'bold', fontSize: 17}}>{t('dcrPage.record')}</div>
                                </div>
                                <div style={{overflowX: 'auto', paddingBottom: 15}}>
                                    {
                                        store.dto.type === RequestTypeEnum.Organization ?
                                            <Organization store={store.data as OrganizationStore}/> :
                                            <Person store={store.data as PersonStore}/>
                                    }
                                </div>
                            </div>
                        }
                        {
                            store.visibilityFieldsNameValues ?
                                <Card variant="outlined" style={{
                                    padding: '20px 0 20px 20px',
                                    border: "unset",
                                    backgroundColor: 'unset'
                                }}>
                                    <CardHeader style={{
                                        padding: "unset",
                                        paddingBottom: 15,
                                        fontSize: '1rem',
                                        fontWeight: "bold"
                                    }}
                                                disableTypography={true}
                                                title={t('dcrPage.fields.addressInfo')}
                                    />
                                    <CardContent style={{padding: 'unset'}}>
                                        <div style={{overflowX: 'auto', paddingBottom: 15}}>
                                            <FieldNameValuesComponent store={store}/>
                                        </div>
                                    </CardContent>
                                </Card>
                                :
                                <>
                                    {
                                        store.visibilityFieldsFirsValues &&
                                        <Card variant="outlined" style={{
                                            padding: '20px 0 20px 20px',
                                            border: "unset",
                                            backgroundColor: 'unset'
                                        }}>
                                            <CardHeader style={{
                                                padding: "unset",
                                                paddingBottom: 15,
                                                fontSize: '1rem',
                                                fontWeight: "bold"
                                            }}
                                                        disableTypography={true}
                                                        title={t('dcrPage.fields.addressInfo')}
                                            />
                                            <CardContent style={{padding: 'unset'}}>
                                                {
                                                    store.dto.type === RequestTypeEnum.Organization ?
                                                        <OrganizationExternalFirstValues
                                                            store={store.data as OrganizationStore}/> :
                                                        <PersonExternalFirstValues
                                                            store={store.data as PersonStore}/>
                                                }
                                            </CardContent>
                                        </Card>
                                    }
                                </>
                        }

                        <div style={{paddingBottom: '30px', marginLeft: 20}}>
                            <>
                                {
                                    <>
                                        <Card style={{
                                            border: "unset",
                                            backgroundColor: 'unset',
                                            boxShadow: 'unset',
                                            borderRadius: 'unset'
                                        }}>
                                            <CardHeader style={{padding: "unset", paddingBottom: 0}}
                                                        titleTypographyProps={{variant: 'h6'}}
                                                        title={t("dcrPage.requestDataInfo")}/>

                                            <CardContent style={{padding: 'unset'}}>
                                                {
                                                    store.blocksInfo.map(block =>
                                                        <>
                                                            <Card variant="outlined" style={{
                                                                padding: '20px 0 20px 20px',
                                                                border: "unset",
                                                                backgroundColor: 'unset'
                                                            }}>
                                                                {

                                                                }
                                                                <CardHeader style={{
                                                                    padding: "unset",
                                                                    paddingBottom: 15,
                                                                    fontSize: '1rem',
                                                                    fontWeight: "bold"
                                                                }}
                                                                            disableTypography={true}
                                                                            title={t("dcrPage.blocks." + block.name)}
                                                                />
                                                                <CardContent style={{padding: 'unset'}}>
                                                                    <List style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        flexWrap: "wrap",
                                                                        alignItems: 'flex-start',
                                                                        padding: 'unset'
                                                                    }}>
                                                                        {block.fields.map((x, i) => {
                                                                                const aa = store.fields.find(t => String(t.field) == x);
                                                                                if (aa) {
                                                                                    return (<>
                                                                                            <DcrListItem
                                                                                                name={store.edit ? '' : `${aa.name} ${aa.store.required ? "*" : ""}`}
                                                                                                width={isOpenInDrawer ? '32%' : '32%'}
                                                                                                style={{marginTop: aa.store.type == "checkbox" ? 8 : 0}}
                                                                                                field={aa}
                                                                                            >
                                                                                                {
                                                                                                    store.edit ?
                                                                                                        <>
                                                                                                            {
                                                                                                                aa.store.type == "string" ?
                                                                                                                    <DefaultValue
                                                                                                                        style={{width: '100%'}}
                                                                                                                        disabled={aa.readonly}
                                                                                                                        store={aa.store as DefaultValueStore<string>}
                                                                                                                        title={aa.name}
                                                                                                                        testid={`RequestDetails-${aa.name}`}
                                                                                                                    /> :
                                                                                                                    aa.store.type == "select" ?
                                                                                                                        <DefaultSelect
                                                                                                                            style={{width: '100%'}}
                                                                                                                            disabled={aa.readonly}
                                                                                                                            hasLabel
                                                                                                                            nameExp={(aa.store as DefaultSelectStore<any>).nameExp}
                                                                                                                            store={aa.store as DefaultSelectStore<any>}
                                                                                                                            title={aa.name}
                                                                                                                            link={aa.link}
                                                                                                                            testid={`RequestDetails-${aa.name}`}
                                                                                                                        /> :
                                                                                                                        <DefaultCheckbox
                                                                                                                            style={{width: '100%'}}
                                                                                                                            disabled={aa.readonly}
                                                                                                                            store={aa.store as DefaultValueStore<boolean>}
                                                                                                                            title={aa.name}
                                                                                                                            testid={`RequestDetails-${aa.name}`}
                                                                                                                        />
                                                                                                            }
                                                                                                        </> :
                                                                                                        <div
                                                                                                            style={valueStyle}
                                                                                                            title={aa.store.readValue}>
                                                                                                            {
                                                                                                                aa.store.readValue
                                                                                                            }
                                                                                                        </div>
                                                                                                }
                                                                                            </DcrListItem>
                                                                                        </>
                                                                                    )
                                                                                } else {
                                                                                    return store.data.customFieldStore[x]?.Component;
                                                                                }
                                                                            }
                                                                        )
                                                                        }
                                                                    </List>
                                                                </CardContent>
                                                            </Card>
                                                            <ToolDivider style={{width: '90%'}}/>
                                                        </>
                                                    )
                                                }
                                                {
                                                    (store.dto.comment ?? "")?.trim() != "" &&
                                                    <div style={{...boxStyleRead, marginTop: 15, width: '100%'}}>
                                                        <div style={{
                                                            color: 'gray',
                                                            marginBottom: 10
                                                        }}>{t("dcrPage.comment")}</div>
                                                        <div style={valueStyle}>
                                                            {store.dto.comment ? store.dto.comment : t("dcrPage.empty")}
                                                        </div>
                                                    </div>
                                                }
                                                {store.specification.DetailsAfterComponent}
                                            </CardContent>
                                        </Card>
                                    </>
                                }
                                <div style={{
                                    fontWeight: 'bold',
                                    fontSize: 17,
                                    paddingRight: 10,
                                    paddingTop: 25,
                                }}>{t('dcrPage.request')}</div>
                                <List>
                                    {
                                        store.specification.DcrInfoFields.map((x, i) =>
                                            <DcrListItem key={i} name={x.name} value={x.value ?? ""}/>)
                                    }
                                </List>
                                <List>
                                    {
                                        Array.from(store.errors.values()).map((x, i) =>
                                            <Box key={i} sx={{pt: 2, color: 'red'}}
                                                 style={{paddingTop: 'unset'}}>{x}</Box>
                                        )
                                    }
                                </List>
                            </>
                        </div>
                    </div>
                </FormRoot>
                {
                    store.loading &&
                    <div style={{
                        position: 'absolute',
                        inset: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress disableShrink/>
                    </div>
                }
            </Box>
        </>
    );
}))({
    minHeight: 38,
    '& .MuiAutocomplete-root': {
        marginTop: '0!important'
    }
});
