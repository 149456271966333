import {observer} from "mobx-react";
import {CreateButtonsGroup, FormRoot, FormRow} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import React from "react";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {WebsiteStatusDto,} from "../../../../services/management";
import {useTranslation} from "react-i18next";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";

interface Props {
    store: DefaultFormModalStore<{
        websiteAddress: DefaultValueStore<string>,
        websiteStatus: DefaultSelectStore<WebsiteStatusDto>,
        description: DefaultValueStore<string>,
    }>;
}

export const PersonWebsiteCreator = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <FormRoot sx={{ p: 2 }}>
            <FormRow>
                <DefaultValue
                    testid="personWebsiteCreator-website"
                    style={{ width: '100%' }}
                    store={store.fields.websiteAddress}
                    title={t('common.orgWebsite')}
                    disabled={store.processing}
                />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.fields.websiteStatus}
                    title={t('common.status')}
                    testid="personWebsiteCreator-status"
                />
            </FormRow>
            <FormRow style={{height: "max-content", marginBottom: 8}}>
                <DefaultValue
                    testid="organizationPhoneCreator-note"
                    style={{width: '100%'}}
                    store={store.fields.description}
                    title={t('common.note')}
                    disabled={store.processing}
                    multiline
                    InputProps={{
                        sx: {
                            width: '100%',
                            "&.MuiInputBase-root": {
                                height: '100%'
                            }
                        }
                    }}
                />
            </FormRow>
            <CreateButtonsGroup
                onCancel={() => store.Open = false}
                onSave={async () => await store.submit()}
                isCancelBtnDisabled={store.processing}
                isSaveBtnDisabled={store.processing || !store.valid}
                testid="personWebsiteCreator"
            />
        </FormRoot>
    );
});
