import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AuthorizePermission,
  ConfirmationForm,
  ExpertDialog,
  Progress,
  SimpleButton
} from "../../../../components";
import { CountIconText, DeleteIconButton, EditIconButton } from "../../../../components/IconButtons";
import { AllPermissions } from "../../../../core/const";
import { useSettings } from "../../../../core/hooks/use-settings";
import { PhoneLogo as PhoneLogoIcon } from '../../../../icons/phone-logo';
import { OrganizationPhoneItemStore } from "../../../../stores/management/organizations/details/phones/OrganizationPhoneItemStore";
import { OrganizationPhoneTableStore } from "../../../../stores/management/organizations/details/phones/OrganizationPhoneTableStore";
import { OrganizationPhoneCreator } from "./OrganizationPhoneCreator";
import { OrganizationPhoneDetails } from "./OrganizationPhoneDetails";

interface Props {
  store: OrganizationPhoneTableStore;
  style?: React.CSSProperties
}

export const OrganizationPhoneCards = observer(({ store, style }: Props) => {
  const settings = useSettings();
  const { t } = useTranslation();
  const [openDetails, setOpenDetails] = useState<string | null>(null);

  const handleOpenDetails = (productId: string): void => {
    setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
  };
  return (
    <>
      <Card
        sx={{ mt: 3, backgroundColor: 'background.default' }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row'
              }
            }}
          >
            <Box sx={{ mr: 2 }}>
              <PhoneLogoIcon />
            </Box>
            <Box>
              <Box>
                <Typography variant="h6" data-testid='OrganizationPhoneCards-title'>{t('common.phone')}</Typography>
                <Typography variant="body2" data-testid='OrganizationPhoneCards-description'>{t('description.phones').replace("phones", t('pageNames.organizations'))}</Typography>
              </Box>
              <CountIconText value={store.count} testid='OrganizationPhoneCards' />
            </Box>
            <Box sx={{ ml: 'auto' }}>
              <AuthorizePermission permissions={AllPermissions.organization.phones.create}>
                <SimpleButton onClick={() => store.creator.Open = true} title={t('common.add')} variant="outlined" testid="orgPhoneCards-create-btn" />
              </AuthorizePermission>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Card
              variant="outlined"
            >
              {store.items.length > 0 ? (<>
                {store.items.map((item: OrganizationPhoneItemStore, index) => {
                  const open = item.dto.id === openDetails;
                  return (
                    <Fragment key={item.dto.id}>
                      <Box
                        sx={{
                          '&:hover': { backgroundColor: settings.paletteMode == 'dark' ? '#1F2937' : '#F8F9FA' },
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexWrap: 'wrap',
                          px: 2,
                          py: 1.5
                        }}
                        onClick={() => store.current = item}
                      >
                        <div>
                          <Typography variant="subtitle1" data-testid='OrganizationPhoneCards-phoneNumber'>
                            {item.dto?.phone?.phoneNumber?.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 ($2) $3-$4-$5")}
                          </Typography>
                          <Box sx={{ display: 'flex', mt: 1.5 }}>
                            <Box sx={{ mr: 3, display: 'flex' }}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ mr: 1 }}
                              >
                                {t('common.status')?.toLowerCase()}
                              </Typography>
                              <Typography
                                variant="body2"
                                data-testid='OrganizationPhoneCards-phoneStatus'
                              >
                                {item.dto?.phoneStatus.nameRus}
                              </Typography>
                            </Box>
                            •
                            <Box sx={{ ml: 3, mr: 3, display: 'flex' }}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ mr: 1 }}
                              >
                                {t('common.type')?.toLowerCase()}
                              </Typography>
                              <Typography
                                variant="body2"
                                data-testid='OrganizationPhoneCards-phoneType'
                              >
                                {item.dto?.phoneType.nameRus}
                              </Typography>
                            </Box>
                            •
                            <Box sx={{ ml: 3, mr: 3, display: 'flex' }}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ mr: 1 }}
                              >
                                {t('common.category')?.toLowerCase()}
                              </Typography>
                              <Typography
                                variant="body2"
                                data-testid='OrganizationPhoneCards-phoneCategory'
                              >
                                {item.dto?.phoneCategory.nameRus}
                              </Typography>
                            </Box>
                          </Box>
                          {item.dto?.additionalNumber &&
                            <Box sx={{ mt: 0.5, display: 'flex' }}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ mr: 1 }}
                              >
                                {t('common.additionalNumber')?.toLowerCase()}
                              </Typography>
                              <Typography
                                variant="body2"
                                data-testid='OrganizationPhoneCards-additionalNumber'
                              >
                                {item.dto?.additionalNumber}
                              </Typography>
                            </Box>
                          }
                          {item.dto?.description &&
                            <Box sx={{ mt: 0.5, display: 'flex' }}>
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                sx={{ mr: 1 }}
                              >
                                {t('common.note')?.toLowerCase()}
                              </Typography>
                              <Typography
                                variant="body2"
                                data-testid='OrganizationPhoneCards-description'
                              >
                                {item.dto?.description?.length > 50 ? `${item.dto?.description.substring(0, 50)}...` : item.dto?.description}
                              </Typography>
                            </Box>
                          }
                        </div>
                        <div>
                          <EditIconButton onClick={() => handleOpenDetails(item.dto.id)} open={open} testid='OrganizationPhoneCards' style={{ marginRight: 16 }} />
                          <AuthorizePermission permissions={AllPermissions.organization.phones.delete}>
                            <DeleteIconButton onClick={() => item.deleteDialogState = true} testid='OrganizationPhoneCards' />
                          </AuthorizePermission>
                        </div>
                      </Box>
                      {open && (
                        <OrganizationPhoneDetails
                          store={item}
                        />
                      )}
                      {(index !== store.items.length - 1) && <Divider />}
                    </Fragment>
                  )
                })}
              </>)
                : <></>
              }
            </Card>
          </Box>
        </CardContent>
      </Card>
      {
        store.current
          ? (
            <ExpertDialog
              title={t('common.delete')}
              open={store.current.deleteDialogState}
              onClose={() => store.current ? store.current.deleteDialogState = false : false}
            >
              <ConfirmationForm
                disabled={store.current.processing}
                onSubmit={async () => await store.current?.delete()}
                onClose={() => store.current ? store.current.deleteDialogState = false : false}
              >
                {t('common.deleteConfirm')} "{store.current.dto?.phone?.phoneNumber}"?
              </ConfirmationForm>
            </ExpertDialog>
          )
          : ''
      }
      <ExpertDialog
        title={t('create.orgPhone')}
        open={store.creator.Open}
        onClose={() => store.creator.Open = false}
      >
        <OrganizationPhoneCreator store={store.creator} />
      </ExpertDialog>
      {(!store.items.length || store.loading || store.processing) && <Progress store={store} />}
    </>
  );
});

