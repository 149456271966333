import {observer} from 'mobx-react';
import {CreateButtonsGroup, FormRoot, FormRow} from '../../../../components';
import {useTranslation} from "react-i18next";
import {
    JobArchiveCreatorStore
} from '../../../../stores/management/persons/tabs/jobs/jobArchive/JobArchiveCreatorStore';
import {DefaultSelect} from '../../../../components/shared/components/DefaultSelect';
import {DefaultValue} from '../../../../components/shared/components/DefaultValue';

interface Props {
    store: JobArchiveCreatorStore;
}

export const JobArchiveCreator = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <FormRoot>
            <FormRow>
                <DefaultValue store={store.creator.fields.personOrganization}
                    disabled
                    style={{ width: '100%'}}
                    testid="createJobForm-personOrganization"
                              title={!store.store.current!.isTypeDepartment ? t('common.organization') : `${t('common.organization')} (${t('common.jobDep')})`}
                    />
            </FormRow>
            <FormRow>
                <DefaultValue store={store.creator.fields.personPost}
                    disabled
                    style={{ width: '100%'}}
                    testid="createJobForm-personPost"
                    title={t('common.position')}
                    />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.creator.fields.personStatus}
                    testid="createJobForm-personStatus"
                    title={t('common.status')}
                    hasLabel />
            </FormRow>
            <FormRow>
                <DefaultValue store={store.creator.fields.recoveryReason} title={t('common.reasonUnarchive')}
                              style={{width: '100%'}}/>
            </FormRow>
            <CreateButtonsGroup
                testid="сreateJobForm"
                onCancel={() => {
                    store.Open(false)
                }}
                onSave={async () => await store.creator.submit()}
                isCancelBtnDisabled={store.creator.processing}
                isSaveBtnDisabled={store.creator.processing || !store.valid}/>
        </FormRoot>
    );
});
