import { Box, List } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { AuthorizePermission, FormRoot, ParamsButtonGroup } from "../../../components";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import { UpdateTickIconButton } from "../../../components/IconButtons";
import { DefaultSelect } from "../../../components/shared/components/DefaultSelect";
import { DefaultValue } from "../../../components/shared/components/DefaultValue";
import { AllPermissions, OrganizationStatuses } from "../../../core/const";
import { OrganizationPropertiesStore } from "../../../stores/management/organizations/details/OrganizationPropertiesStore";
import { DcrListItem } from "../../dcr/DcrListItem";
import {NavLink} from "react-router-dom";
import {Link as LinkIcon} from "../../../icons/link";

interface Props {
    store: OrganizationPropertiesStore;
    style?: React.CSSProperties
}

export const OrganizationProperties = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    return (
        <FormRoot style={style}>
            <List style={{ marginBottom: 6 }}>
                <DcrListItem name={`Id ${t('common.organizations').toLowerCase()}`}>
                    {`${store.dto.id} `}
                    <CopyToClipboard copyText={store.dto.id} />
                </DcrListItem>
            </List>
            <DefaultValue
                title={t('common.itn')}
                style={{ marginTop: 10 }}
                store={store.itn}
                disabled={store.processing} />
            <DefaultSelect store={store.organizationStatus}
                disabled={store.dto.status?.id === OrganizationStatuses.Double}
                title={t('common.status')}
                nameExp={(dto) => dto.nameRus || 'Работает'}
                style={{ marginRight: 20, marginTop: 24 }}
                testid="organizationProperties-organizationStatus"
                hasLabel />
            <DefaultSelect store={store.organizationType}
                title={t('common.type')}
                style={{ marginRight: 20, marginTop: 24 }}
                testid="organizationProperties-organizationType"
                hasLabel />
            <DefaultValue
                title={t('common.clinicCategory')}
                style={{ marginTop: 30 }}
                store={store.clinicCategory}
                disabled={true} />
            <DefaultSelect store={store.organizationFormProperties}
                title={t('common.formProperty')}
                style={{ marginTop: 24 }}
                testid="organizationProperties-organizationFormProperties"
                hasLabel />
            {store.dto.status?.id === OrganizationStatuses.Double &&
                <>
                    <Box style={{ display: 'flex', width: '100%' }} sx={{ mt: 2, mb: 1 }}>
                        <DefaultValue
                            store={store.doble}
                            style={{ width: 400 }}
                            title={`${t('common.antiDoble')} ID`}
                            disabled={true}
                        />
                        <Box sx={{ mt: 2.5 }}>
                            <CopyToClipboard copyText={store.doble.value!} />
                        </Box>
                        <Box sx={{ mt: 2.5, ml: 1, mr: 1 }}>
                            <NavLink
                                to={`/organizations/${store.doble.value}`}
                                target="_blank">
                                <LinkIcon fontSize="small"/>
                            </NavLink>
                        </Box>
                    </Box>
                </>
            }
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2
                }}
            >
                <AuthorizePermission permissions={AllPermissions.organization.update}>
                    <ParamsButtonGroup
                        testid="organizationProperties"
                        onCancel={() => store.reset()}
                        onSave={async () => store.handlerSubmit()}
                        isCancelBtnDisabled={store.store.processing || store.equals}
                        isSaveBtnDisabled={store.store.processing || store.equals || !store.valid}
                        processing={store.store}
                    />

                    <UpdateTickIconButton onClick={async () => await store.updateTick()} testid='organizationProperties' />
                </AuthorizePermission>
            </Box>
        </FormRoot>
    );
});

