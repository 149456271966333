import { makeObservable, observable, action, computed } from 'mobx';
import { OrganizationItemStore } from '../../../index';
import { getApiConfiguration, getObject, setObject } from '../../../../../core';
import {
    EmailStatusDto,
    OrganizationEmailStatusesApi,
    EmailTypeDto,
    OrganizationEmailTypesApi,
    OrganizationDto, OrganizationEmailsApi,
} from '../../../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../utils';
import { DefaultSelectStore } from "../../../../../components/shared/DefaultSelectStore";
import { DefaultValueStore } from "../../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import { OrganizationEmailItemStore } from './OrganizationEmailItemStore';
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";


export class OrganizationEmailTableStore extends TableStore<OrganizationEmailItemStore> {
    _orgStore: OrganizationItemStore
    _dto: OrganizationDto
    organizationId: string

    current: OrganizationEmailItemStore | undefined | null;
    readonly creator: DefaultFormModalStore<{
        emailAddress: DefaultValueStore<string>,
        type: DefaultSelectStore<EmailTypeDto>,
        status: DefaultSelectStore<EmailStatusDto>,
    }> = new DefaultFormModalStore({
            emailAddress: new DefaultValueStore<string>("",
                (value) => new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/).test(value ?? "") ? "" : i18n.t('errors.email')),
            type: new DefaultSelectStore<EmailTypeDto>(
                null, (x) => new OrganizationEmailTypesApi(getApiConfiguration()).getOrganizationEmailTypes(x),
                (value) => !value ? i18n.t('errors.empty') : ""),
            status: new DefaultSelectStore<EmailStatusDto>(
                null, (x) => new OrganizationEmailStatusesApi(getApiConfiguration()).getOrganizationEmailStatuses(x),
                (value) => !value ? i18n.t('errors.empty') : ""),
        },

        (model) => this.create(model));

    constructor(orgStore: OrganizationItemStore, dto: OrganizationDto) {
        super();
        this._orgStore = orgStore
        this._dto = dto
        this.organizationId = this._dto.id


        const filters = getObject<TableLocalFilters<OrganizationEmailItemStore>>('organizations-email-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            organizationId: observable,
            current: observable,
        });
    }

    async request(): Promise<PagedItems<OrganizationEmailItemStore>> {
        const countFilters = {
            organizationId: this._dto.id,
        }
        const api: OrganizationEmailsApi = new OrganizationEmailsApi(getApiConfiguration());
        const count = await api.getOrganizationEmailsCount(countFilters);

        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            organizationId: this.organizationId,
        };
        const result = await api.getOrganizationEmails(filters);

        const newList: OrganizationEmailItemStore[] = [];
        for (const item of result) {
            newList.push(new OrganizationEmailItemStore(this, item))
        }

        return new PagedItems<OrganizationEmailItemStore>(newList, count.count)
    };

    async create(model: any): Promise<void> {
        this.processing = true;
        const api: OrganizationEmailsApi = new OrganizationEmailsApi(getApiConfiguration());
        try {
            const result = await api.setOrganizationEmails({
                organizationId: this._dto.id,
                setOrganizationEmailDto: [
                    {
                        organizationId: this._dto.id,
                        emailAddress: this.creator.fields.emailAddress.value!,
                        emailStatusId: this.creator.fields.status.value?.id!,
                        emailTypeId: this.creator.fields.type.value?.id!,
                        description: ''
                    }
                ]
            });
            await this.pull()
        } finally {
            this.processing = false;
        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<OrganizationEmailItemStore>>('organizations-email-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
