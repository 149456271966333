import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IForm, IModal, IProcessing} from '../../../../core';
import {AddressUniquesApi, OrganizationsApi, ZipDto, ZipsApi,} from '../../../../services/management';
import {OrganizationTableStore} from "../OrganizationTableStore";
import {FormPropertyCreatorStore} from "./FormPropertyCreatorStore";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import {DefaultAddressesStore} from "../../../../shared/address/DefaultAddressStore";
import {JurisdictionData, JurisdictionLocalSetting} from "../../../authorization/AuthorizationStore";

export class OrganizationCreatorStore implements IModal, IForm, IProcessing {
    readonly _store: OrganizationTableStore;
    readonly api: OrganizationsApi = new OrganizationsApi(getApiConfiguration());
    readonly apiAddress: AddressUniquesApi = new AddressUniquesApi(getApiConfiguration());
    processing: boolean = false;

    _open: boolean = false;

    address: DefaultAddressesStore;
    properties: FormPropertyCreatorStore;

    readonly steps = ['Address', 'Form Property'];

    _step: number = 0;


    zip: DefaultSelectStore<ZipDto>;

    constructor(store: OrganizationTableStore) {
        this._store = store;

        this.address = new DefaultAddressesStore(undefined, this);
        this.properties = new FormPropertyCreatorStore(this);
        // this.properties.getTypeName()

        this.zip = new DefaultSelectStore<ZipDto>(null, (x) => new ZipsApi(getApiConfiguration()).getZips(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        makeAutoObservable(this);
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._store?.store?.store?.authorizationStore?.jurisdictionsSettings
    }


    get validStep(): boolean {
        switch (this.step) {
            case 0:
                return this.address.valid
            case 1:
                return this.properties.form.valid
            default:
                return true;
        }
    }

    get valid(): boolean {
        return this.address.valid && this.properties.form.valid
    }

    back() {
        this.step = this.step - 1;
        if (this.step == 1) {
            this.properties.form.fields.type.reset()
        }
    }

    async next() {
        await this.validate();

        if (!this.validStep) return;

        if (this.step === this.steps.length - 1) await this.submit();

        if (this.validStep) this.step = this.step + 1;
    }

    set step(value: number) {
        this._step = value;
    }

    get step(): number {
        return this._step;
    }

    async validate() {
        switch (this.step) {
            case 0: {
                await this.address.validate();
                break
            }
            case 1: {
                this.properties.form.validate();
                break
            }
        }
    }


    get Open() {
        return this._open;
    }

    set Open(value: boolean) {
        this._open = value;
        this.reset();
    }

    reset(): void {
        this.address.reset();
        this.properties.form.reset();
        this.step = 0;
    }

    async submit(): Promise<void> {
        this.processing = true;
        try {
            const isCustomCoordinates = !!this.address.isCustomCoordinates.value;
            await new OrganizationsApi(getApiConfiguration()).createOrganization({
                addOrganizationDto: {
                    itn: this.properties.form.fields.itn.value,
                    formPropertyId: this.properties.form.fields.formProperty.value?.id!,
                    parentId: this.properties.form.fields.parentOrganization.value?.id!,
                    typeOrgId: this.properties.form.fields.type.value?.id!,
                    statusId: this.properties.form.fields.status.value?.id ?? '',
                    names: [{
                        name: this.properties.form.fields.name.value!,
                        typeNameId: JurisdictionData.orgTypeNames.syndicate
                    }],
                    phones: this.properties.form.fields.phoneNumber?.value ? [{
                        phoneNumber: this.properties.form.fields.phoneNumber?.value!,
                        phoneStatusId: this.properties.form.fields.phoneStatus.value?.id!,
                        phoneCategoryId: this.properties.form.fields.phoneCategory.value?.id!,
                        phoneTypeId: this.properties.form.fields.phoneType.value?.id!,
                        additionalNumber: this.properties.form.fields.additionalNumber?.value!,
                    }] : [],
                    address: {
                        localityId: this.address.locality?.currentLocality?.id!,
                        building: this.address.building.value,
                        house: this.address.house.value!,
                        zip: this.address.zip.value!,
                        districtId: this.address.district.value?.id!,
                        okrugId: this.address.okrug.value?.id!,
                        latitude: isCustomCoordinates ? this.address.latitude.value!.replace(/\./g, ',') : "11",
                        longtitude: isCustomCoordinates ? this.address.longtitude.value!.replace(/\./g, ',') : "22",
                        gpsAccuracy: "123",
                        entryTypeId: this.address.entryType.valueId,
                        entryValue: this.address.entryValue.value
                    }!,
                },
                isCustomCoordinates: isCustomCoordinates
            })
            this.Open = false;
            this.reset();
            await this._store.pull();
        } catch (e) {
            this.back()
        }
        this.processing = false;
    }
}
