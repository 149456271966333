import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, IForm, IModal, IProcessing} from '../../../core';
import {PersonTableStore} from "./PersonTableStore";
import {DefaultFormStore} from "../../../components/shared/DefaultFormStore";
import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import {
    FirstNameDto,
    FirstNamesApi,
    GenderDto,
    GendersApi,
    ListSortDirection,
    MiddleNameDto,
    MiddleNamesApi,
    PersonsApi,
    SpecialitiesApi,
    SpecialityDto,
    SurnameDto,
    SurnamesApi
} from "../../../services/management";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";
import {DefaultMultiSelectStore} from "../../../components/shared/DefaultMultiSelectStore";
import {JobCreatorStore} from "./tabs/jobs/JobCreatorStore";
import {PersonItemStore} from "./PersonItemStore";

export class PersonCreatorStore implements IModal, IForm, IProcessing {
    readonly _store: PersonTableStore;
    processing: boolean = false;

    _open: boolean = false;

    property: DefaultFormStore<{
        name: DefaultSelectStore<FirstNameDto>,
        middleName: DefaultSelectStore<MiddleNameDto>,
        surname: DefaultSelectStore<SurnameDto>,
        hasNoMiddleName: DefaultValueStore<boolean>,
        gender: DefaultSelectStore<GenderDto>
    }> = new DefaultFormStore({
        gender: new DefaultSelectStore(null,
            (x) => new GendersApi(getApiConfiguration()).getGenders(x)),
        middleName: new DefaultSelectStore(null,
            (x) => {
                let params = {...x, ...{order: ListSortDirection.Ascending, orderBy: 'nameRus'}}
                return new MiddleNamesApi(getApiConfiguration()).getMiddleNames(params);
            }),
        surname: new DefaultSelectStore(null,
            (x) => {
                let params = {...x, ...{order: ListSortDirection.Ascending, orderBy: 'nameRus'}}
                return new SurnamesApi(getApiConfiguration()).getSurnames(params);
            }),
        name: new DefaultSelectStore(null,
            (x) => {
                let params = {...x, ...{order: ListSortDirection.Ascending, orderBy: 'nameRus'}}
                return new FirstNamesApi(getApiConfiguration()).getFirstNames(params);
            }),
        hasNoMiddleName: new DefaultValueStore<boolean>(false)
    })

    speciality: DefaultFormStore<{
        specialities: DefaultMultiSelectStore<SpecialityDto>,
        primary: DefaultSelectStore<SpecialityDto>,
    }> = new DefaultFormStore({
        specialities: new DefaultMultiSelectStore([], (x) => new SpecialitiesApi(getApiConfiguration()).getSpecialities({
            ...x,
            showOnlyActive: true
        })),
        primary: new DefaultSelectStore<SpecialityDto>(null, (x) => Promise.resolve(this.speciality.fields.specialities.value ?? []))
    })

    job: JobCreatorStore = new JobCreatorStore();

    readonly steps = ['property', 'speciality', 'job'];

    step: number = 0;

    constructor(store: PersonTableStore) {
        this._store = store;

        this.property.fields.gender.required = true;
        this.property.fields.name.required = true;
        this.property.fields.surname.required = true;


        this.property.fields.hasNoMiddleName.onChanged.push((e) => {
            this.property.fields.middleName.required = !e
            this.property.fields.middleName.reset()
        })


        this.speciality.fields.primary.required = true;
        this.speciality.fields.specialities.required = true;

        this.speciality.fields.specialities.onChanged.push((e) => {
            if (e && e.length > 0) {
                if (!this.speciality.fields.primary.value) {
                    this.speciality.fields.primary.value = e[0]
                } else {
                    if (!e.map(x => x.id).find(x => x == this.speciality.fields.primary.value?.id)) {
                        this.speciality.fields.primary.value = e[0]
                    }
                }
            } else {
                this.speciality.fields.primary.value = null;
            }
        })

        makeAutoObservable(this);
    }

    get validStep(): boolean {
        switch (this.step) {
            case 0:
                return this.property.valid
            case 1:
                return this.speciality.valid
            case 2:
                return this.job.valid
            default:
                return true;
        }
    }

    get valid(): boolean {
        return this.property.valid && this.speciality.valid && this.job.valid
    }

    back() {
        this.step = this.step - 1;
    }

    async next() {
        await this.validate();

        if (!this.validStep) return;

        if (this.step === this.steps.length - 1)
            await this.submit();

        else {
            this.step = this.step + 1;
            if (this.step == 2) {
                await this.job.pull()
            }
        }
    }

    async validate() {
        switch (this.step) {
            case 0: {
                this.property.validate();
                break
            }
            case 1: {
                this.speciality.validate();
                break
            }
            case 2: {
                this.job.validate();
                break
            }
        }
    }


    get Open() {
        return this._open;
    }

    set Open(value: boolean) {
        this._open = value;
        this.reset();
    }

    reset(): void {
        this.property.reset();
        this.speciality.reset();
        this.job.reset();
        this.step = 0;
    }

    async submit(): Promise<void> {
        this.processing = true;

        await this.validate()

        if (!this.valid) {
            return
        }

        try {
            const person = await new PersonsApi().createPerson({
                createPersonDto: {
                    firstNameId: this.property.fields.name.valueId,
                    hasNoMiddleName: !!this.property.fields.hasNoMiddleName.value,
                    genderId: this.property.fields.gender.valueId,
                    middleNameId: this.property.fields.middleName.valueId,
                    primarySpecialityId: this.speciality.fields.primary.valueId,
                    surnameId: this.property.fields.surname.valueId,
                    specialityIds: this.speciality.fields.specialities.value.map(x => x.id).filter(x => x != this.speciality.fields.primary.valueId),
                    jobPostId: this.job.personPost.valueId,
                    jobOrganizationId: this.job.isDepOrg ? undefined : this.job.personOrganization.value?.id!,
                    depJobOrganizationId: this.job.isDepOrg ? this.job.personOrganization.value?.id! : undefined,
                    jobStatusId: 1
                }
            })

            this.Open = false;
            this._store.items.pop();
            this._store.items.unshift(new PersonItemStore(this._store, person))
        } catch (e) {

        }
        this.processing = false;
    }
}
