import {observer} from 'mobx-react';
import {CreateButtonsGroup} from '../../components';
import {useTranslation} from "react-i18next";
import React from "react";
import {Box} from "@mui/material";
import {DefaultValue} from "../../components/shared/components/DefaultValue";
import {DefaultFormModalStore} from "../../components/shared/DefaultFormModalStore";
import {DefaultValueStore} from "../../components/shared/DefaultValueStore";

interface Props {
    store: DefaultFormModalStore<{
        nameRus: DefaultValueStore<string>,
        nameEng: DefaultValueStore<string>,
        itnRegex: DefaultValueStore<string>,
        phoneNumberRegex: DefaultValueStore<string>
    }>
}


export const JurisdictionCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <Box sx={{p: 2}}>
            <DefaultValue title={t('jurisdictions.nameRus')}
                          store={store.fields.nameRus}/>
            <DefaultValue title={t('jurisdictions.nameEng')}
                          store={store.fields.nameEng}
            />
            <DefaultValue title={t('jurisdictions.itnRegex')}
                          store={store.fields.itnRegex}
            />
            <DefaultValue title={t('jurisdictions.phoneNumberRegex')}
                          store={store.fields.phoneNumberRegex}
            />
            <CreateButtonsGroup onCancel={() => store.Open = false}
                                isCancelBtnDisabled={store.processing}
                                onSave={async () => await store.submit()}
                                isSaveBtnDisabled={store.processing || !store.valid}/>
        </Box>
    );
});
