import {observer} from 'mobx-react';
import {Box, Button, Step, StepLabel, Stepper, Typography} from '@mui/material';
import React from "react";
import {AddressCreator} from "./steps/AddressCreator";
import {FormPropertyCreator} from "./steps/FormPropertyCreator";
import {useTranslation} from "react-i18next";
import {OrganizationCreatorStore} from "../../../stores/management/organizations/creator/OrganizationCreatorStore";

interface Props {
    store: OrganizationCreatorStore;
}

export const OrganizationCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <Box sx={{ width: '100%', marginTop: 1 }}>
            <Stepper activeStep={store.step}>
                {store.steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps} sx={{ mx: 1 }}>{t(`common.${label}`)}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
                <React.Fragment>
                    <Typography sx={{ mt: 3 }}>
                        {
                            store.step === 0 &&
                            <AddressCreator store={store.address}/>
                        }
                        {
                            store.step === 1 &&
                            <FormPropertyCreator store={store.properties}/>
                        }

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1 }}>
                        <Button
                            color="inherit"
                            disabled={store.step == 0}
                            onClick={() => store.back()}
                            sx={{ mr: 1 }}
                        >
                            {t('buttons.back') }
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={async () => {
                            await store.next()
                            if(store.step === 2) {
                                store.reset()
                                store.address.locality.reset()
                                store.step = 0
                            }
                        }}
                            disabled={store.processing || !store.validStep}>
                            {store.step === store.steps.length - 1 ? t('buttons.finish') : t('buttons.next')}
                        </Button>
                    </Box>
                </React.Fragment>
        </Box>
    );
});
