import {makeAutoObservable} from 'mobx';
import {getApiConfiguration, ILoad} from "../../../../../../core";
import {FieldInfoDto, RequestDto} from "../../../../../../services/dcr";
import {
    AddressUniqueDto,
    AddressUniquesApi,
    JobsApi,
    PersonDto,
    PersonJobDto,
    PersonsApi,
    PersonsMappingsApi,
    PersonSpecialitiesApi,
    PersonSpecialityDto,
    PersonStatusDto,
    PostDto,
} from "../../../../../../services/management";
import {RequestDetailsStore} from "../../RequestDetailsStore";
import {getOrganizationAddressHandler, getOrgName,} from "../../../../../utils";
import {DCRStatusEnum, PersonField, RequestField} from "../../../types";
import {JobStatusesNew} from "../../../../../../core/const";
import {
    CheckboxBuilder,
    NameBuilder,
    OriginalIdBuilder,
    SexBuilder,
    SpecializationBuilder,
} from "../../builders/PersonBuilders";
import {BaseFieldBuilder, DoubleBuilderBuilder} from "../../builders/BaseFieldBuilder";
import {CustomFieldStore} from "../../builders/fieldsStores/CustomFieldStore";
import {JobStore} from "../../builders/fieldsStores/JobStore";
import {PersonJobFields} from "../../../services";
import {JurisdictionData} from "../../../../../authorization/AuthorizationStore";

export class PersonStore implements ILoad {
    readonly _store: RequestDetailsStore;
    data: PersonDto = {} as PersonDto;
    specialities: PersonSpecialityDto[] = [];
    jobs: PersonJobDto[] = [];
    addressDto: AddressUniqueDto = {} as AddressUniqueDto;
    processing: boolean = false;
    loading: boolean = false;
    loaded: boolean = false;
    _customFieldStore: { [key in string]: CustomFieldStore<PersonStore> } = {}

    builders:  { [key in string]: BaseFieldBuilder<PersonStore> } = {
        specialization: new SpecializationBuilder(this),
        sex: new SexBuilder(this),
        lastname: new NameBuilder(this),
        middlename: new NameBuilder(this),
        firstname: new NameBuilder(this),
        originalId: new OriginalIdBuilder(this),
        isPrimarySpeciality: new CheckboxBuilder(this),
        withoutMiddlename: new CheckboxBuilder(this),
        isDouble: new DoubleBuilderBuilder(this)
    }

    get customFieldStore(): { [key in string]: CustomFieldStore<PersonStore> } {
        if (!this._customFieldStore['job'] &&
            (this.loaded || this.dto.dcrStatus === DCRStatusEnum.Create) &&
            this._store.requestFields.find(x => PersonJobFields.indexOf(x.field!) > -1)) {
            this._customFieldStore['job'] = new JobStore(this);
        }
        return this._customFieldStore;
    }

    constructor(store: RequestDetailsStore) {
        this._store = store;

        makeAutoObservable(this);
    }

    get dto(): RequestDto{
        return this._store.dto;
    }

    get primaryJob(): PersonJobDto | undefined {
        return this.jobs.filter(x => x.id === this.data.primaryJob?.id)[0] ?? this.jobs[0];
    }

    get jobInfo(): {
        id: string,
        name: string,
        organizationId: string,
        position: string,
        status: PersonStatusDto | undefined,
        positionId: string,
        post: PostDto,
    } | null {
        if (!this.primaryJob) return null;
        return {
            id: this.primaryJob.id,
            name: getOrgName(this.primaryJob.organization),
            organizationId: this.primaryJob.organization.id,
            position: this.primaryJob.post.nameRus,
            positionId: this.primaryJob.post.id,
            status: this.primaryJob.status ?? undefined,
            post: this.primaryJob.post,
        }
    }

    get jobsCount(): number {
        if (!this.jobs.length) return 0;
        let persJobs = this.jobs
            .filter(x => x.status?.id == JobStatusesNew.Work && x.organization.typeOrg.parentId != JurisdictionData.organizationTypes.depsId &&
                ((x.organization.id != this.jobInfo?.organizationId) ||
                    (x.organization.id == this.jobInfo?.organizationId &&
                        this.jobInfo?.positionId != x.post.id)))
        return persJobs.length;
    }


    get address(): string {
        return !!this.addressDto ? getOrganizationAddressHandler(this.addressDto) : ""
    }

    get errorMessage(): string {
        return this.dto?.data360Id ?
            `Персона с Id: ${this.dto?.data360Id} - не найдена!` :
            this.dto?.externalId ?
                `Персона с ExternalId: ${this.dto?.externalId} - не найдена!` :
                this._store.notVerifiedCreateRequestId ? `Запрос на создание ${this._store.notVerifiedCreateRequestId} не верифицирован` :
                    'Не удалось найти запись в Data360'
    }

    async pull() {
        this.loading = true;
        try {
            await this.loadPerson()
        } finally {
            this.loading = false;
            this.loaded = true;
        }
    }

    async getEntityId(): Promise<string | undefined> {
        const api_ = new PersonsMappingsApi(getApiConfiguration());
        return (await api_.getPersonsMappings({
            crmId: this.dto.crmId,
            boundId: this.dto.boundId ?? undefined
        })).find((x) => x.entityId)?.entityId
    }

    async loadPerson(): Promise<void> {
        let originalId;
        const api = new PersonsApi(getApiConfiguration());
        const originalIdField = this._store.requestFields.find(x => x.field == "originalId");
        if(!!originalIdField){originalId = originalIdField?.value;}
        else{originalId = this._store.dto.data360Id;}
        

        if (this.dto.dcrStatus === 1) {
            this.data = this.dto.data360Id ?
                await api.getPersonById({id: this.dto.data360Id}) :
                this.dto.externalId ?
                    (await api.getPersons({searchByExternalId: this.dto.externalId.toString()}))[0] :
                    await api.getPersonById({id: await this.getEntityId() || ''})
        } else {
            this.data = this.dto.status == 7 ?
                await api.getPersonById({id: originalId || ""}) :
                this.dto.data360Id ?
                    await api.getPersonById({id: this.dto.data360Id}) :
                    (await api.getPersons({searchByExternalId: this.dto.externalId.toString()}))[0]
        }

        if (this.data.id) {
            this.specialities = await new PersonSpecialitiesApi(getApiConfiguration())
                .getPersonSpecialities({
                    size: 1000,
                    personId: this.data.id
                })
            this.jobs = await new JobsApi(getApiConfiguration())
                .getAllPersonJobs({
                    size: 1000,
                    personId: this.data.id
                })

            let job = this.jobs.filter(x => x.id === this.data.primaryJob?.id)[0] ?? this.jobs[0];
            if (job) {
                this.addressDto = await new AddressUniquesApi(getApiConfiguration())
                    .getAddressUniqueById({
                        id: job.organization.addressUniqueId
                    })
            }
        }
    }

    
    async changeAfterInitFields(fields: RequestField[]): Promise<void>{

    }

    async generateField(element: FieldInfoDto): Promise<RequestField> {
        return await this.builders[<PersonField>element.field].build(element)
    }
}
