import { makeAutoObservable } from 'mobx';
import { getApiConfiguration, IExpanded, IForm, IProcessing } from '../../../../../core';
import {
    OrganizationPhoneDto,
    OrganizationPhonesApi, OrganizationPhoneCategoriesApi, PhoneCategoryDto,
    PhoneStatusDto,
    OrganizationPhoneStatusesApi, PhoneTypeDto, OrganizationPhoneTypesApi
} from '../../../../../services/management';
import { OrganizationPhoneTableStore } from "./OrganizationPhoneTableStore";
import { DefaultSelectStore } from "../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import { DefaultValueStore } from '../../../../../components/shared/DefaultValueStore';

export class OrganizationPhoneItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationPhoneTableStore;
    dto: OrganizationPhoneDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    orgPhoneId: string

    status: DefaultSelectStore<PhoneStatusDto>
    type: DefaultSelectStore<PhoneTypeDto>
    category: DefaultSelectStore<PhoneCategoryDto>
    additionalNumber: DefaultValueStore<string>
    description: DefaultValueStore<string>

    constructor(store: OrganizationPhoneTableStore, dto: OrganizationPhoneDto) {
        this._store = store;
        this.dto = dto;
        this.orgPhoneId = dto.id;

        this.status = new DefaultSelectStore<PhoneStatusDto>(
            dto.phoneStatus, (x) => new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.type = new DefaultSelectStore<PhoneTypeDto>(
            dto.phoneType, (x) => new OrganizationPhoneTypesApi(getApiConfiguration()).getOrganizationPhoneTypes(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.category = new DefaultSelectStore<PhoneCategoryDto>(
            dto.phoneCategory, (x) => new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(x),
            (value) => !value ? i18n.t('errors.empty') : "")

        this.additionalNumber = new DefaultValueStore<string>(dto?.additionalNumber ?? '', (value) =>
            value?.length! > 50 ? i18n.t('errors.lengthMax').replace("lengthMax", "50") : "")

        this.description = new DefaultValueStore<string>(dto?.description ?? '', (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")


        makeAutoObservable(this);
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: OrganizationPhonesApi = new OrganizationPhonesApi(getApiConfiguration());
        try {
            const result = await api.deleteOrganizationPhone({
                id: this.orgPhoneId
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result.id);
            await this._store.pull();
        }
        catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.dto.phoneStatus.id === this.status.value?.id
            && this.dto.phoneCategory.id === this.category.value?.id
            && this.dto.phoneType.id === this.type.value?.id
            && this.dto.additionalNumber === this.additionalNumber.value
            && this.dto.description === this.description.value
    }

    get valid(): boolean {
        return this.status.valid && this.category.valid && this.type.valid && this.additionalNumber.valid && this.description.valid;
    }

    validate() {
        this.status.validate();
        this.category.validate();
        this.type.validate();
    }

    update(dto: OrganizationPhoneDto) {
        this.dto = dto;
        this.status.update(dto.phoneStatus);
        this.type.update(dto.phoneType);
        this.category.update(dto.phoneCategory);
        this.additionalNumber.update(dto?.additionalNumber || '');
        this.description.update(dto?.description || '');
    }

    reset(): void {
        this.status.reset();
        this.category.reset();
        this.type.reset();
        this.additionalNumber.reset();
        this.description.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrganizationPhonesApi = new OrganizationPhonesApi(getApiConfiguration());

        try {
            let item = await api.updateOrganizationPhone({
                id: this.orgPhoneId,
                setOrganizationPhoneDto:
                {
                    organizationId: this._store.organizationId,
                    phoneStatusId: this.status.value?.id!,
                    phoneTypeId: this.type.value?.id!,
                    phoneNumber: this.dto.phone?.phoneNumber,
                    phoneCategoryId: this.category.value?.id!,
                    additionalNumber: this.additionalNumber.value,
                    description: this.description.value,
                }
            })
            this.update(item);
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
