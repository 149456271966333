import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Autocomplete, Box, Popper, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { AllPermissions, OrganizationStatuses } from '../../../core/const';
import { DcrListItem } from '../../../pages/dcr/DcrListItem';
import { getOrgName, getOrganizationAddressHandler } from "../../../stores";
import { AuthorizePermission } from "../../Authorize";
import { PanelButton } from "../../Buttons";
import { CopyToClipboard } from '../../CopyToClipboard';
import { OrganizationParentFilterVM } from "./OrganizationParentFilterVM";

interface Props {
    organization: OrganizationParentFilterVM;
    style?: React.CSSProperties;
    zIndex?: number;
}

export const OrganizationParentSelect = observer(({ organization, style, zIndex = 1300 }: Props) => {
    const [text, setText] = useState(organization._search);
    const { t } = useTranslation();

    useEffect(() => {

        const timeOutId = setTimeout(async () => {
            if (text === organization._search) {
                return;
            }

            await organization.setSearch(text);

        }, 500);
        return () => clearTimeout(timeOutId);
    }, [text]);


    return (
        <>
            <div style={{ display: 'flex', marginBottom: 25, marginTop: 25 }}>
                <Autocomplete
                    options={organization.items.filter(x => x.statusId !== OrganizationStatuses.Double)}
                    getOptionLabel={getOrgName}
                    PopperComponent={({ style, ...props }) => (
                        <Popper
                            {...props}
                            placement="bottom"
                            style={{ ...style, zIndex: zIndex, height: 0 }}
                        />
                    )}
                    ListboxProps={{ style: { maxHeight: '30vh' } }}
                    clearOnBlur
                    autoComplete
                    fullWidth
                    loading={organization.loading}
                    filterOptions={t => t}
                    value={organization.value}
                    onFocusCapture={() => setText("")}
                    onChange={(e, t) => {
                        organization.value = t
                    }}
                    onOpen={async () => await organization.pull()}
                    onInputChange={(e, t) => {
                        if (e?.type === 'change')
                            setText(t)
                    }}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={organization.id ? organization.value ? getOrgName(organization.value) : t('common.organization') : t('common.organization')}
                            margin='dense'
                            placeholder={organization.id ? organization.value ? getOrgName(organization.value) : t('common.organization') : t('common.organization')}
                            variant="standard"
                            autoComplete='off'
                        />
                    }
                />
                <div style={{ width: "auto", marginTop: "auto" }}>
                    {organization._store?.id && <PanelButton onClick={() => organization.delete()}>
                        <DeleteOutlineOutlinedIcon color='primary' />
                    </PanelButton>}
                </div>
            </div>
            {
                (organization && organization.value) && <Box sx={{ m: 4 }}>
                    {/*<Grid>{store.organization.value?.addressUnique ? getOrganizationAddressHandler(store.organization.value?.addressUnique) : ''}</Grid>*/}
                    <DcrListItem name={'Id'}>
                        <NavLink to={`/organizations/${organization.value.id}`} target="_blank">
                            {organization.value.id}
                        </NavLink>
                        <CopyToClipboard copyText={organization.value.id} /></DcrListItem>
                    <DcrListItem name={t("common.Address")}
                        value={organization.value?.addressUnique ? getOrganizationAddressHandler(organization.value?.addressUnique) : t("dcrPage.empty")} />
                    <DcrListItem name={t("common.itn")}
                        value={organization.value?.itn.itn ? organization.value?.itn.itn : t("dcrPage.empty")} />
                </Box>
            }
            {organization.value && !organization.organization && !organization.parent &&
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <AuthorizePermission permissions={AllPermissions.organization.update}>
                        <PanelButton
                            onClick={() => organization.add()}
                        >
                            <AddBoxOutlinedIcon color='primary' />
                        </PanelButton>
                    </AuthorizePermission>
                </div>}
            {organization.organization && <OrganizationParentSelect organization={organization.organization} />}

        </>
    );
});
