import {makeObservable, observable} from 'mobx';
import {PagedItems, TableStore} from "../../../../utils";
import {JobItemStore} from "./JobItemStore";
import {getApiConfiguration} from "../../../../../core";
import {
    JobsApi,
    JobStatusesApi,
    JobTagsApi,
    OrganizationDto,
    OrganizationsApi,
    PersonsApi,
    PersonStatusDto,
    PostDto,
    PostsApi,
    TagDto,
    TypeOrgsApi,
} from "../../../../../services/management";
import {PersonItemStore} from "../../PersonItemStore";
import {JobCreatorStore} from "./JobCreatorStore";
import {DefaultMultiSelectStore} from '../../../../../components/shared/DefaultMultiSelectStore';
import {PersonStatusesNew} from '../../../../../core/const';
import {JurisdictionData} from "../../../../authorization/AuthorizationStore";

export class JobsTableStore extends TableStore<JobItemStore> {
    readonly store: PersonItemStore;
    deleteDialogState: boolean = false;

    current: JobItemStore | undefined | null;
    readonly creator: JobCreatorStore

    readonly organizationFilter: DefaultMultiSelectStore<OrganizationDto> = new DefaultMultiSelectStore([],
        (filters) => new OrganizationsApi(getApiConfiguration()).getOrganizations(filters),
        async (items) => await this.updateState())

    readonly postFilter: DefaultMultiSelectStore<PostDto> = new DefaultMultiSelectStore([],
        (filters) => new PostsApi(getApiConfiguration()).getPosts({
            ...filters,
            orderBy: 'name_rus',
            order: 'ascending'
        }),
        async (items) => await this.updateState());

    readonly statusFilter: DefaultMultiSelectStore<PersonStatusDto> = new DefaultMultiSelectStore([],
        (filters) => new JobStatusesApi(getApiConfiguration()).getJobStatuses({...filters}),
        async (items) => await this.updateState());

    readonly tagsFilter: DefaultMultiSelectStore<TagDto> = new DefaultMultiSelectStore([],
        (filters) => new JobTagsApi(getApiConfiguration()).getJobTags({...filters}),
        async (e) => await this.updateState());

    typeDepartmentList: string[] = [];

    constructor(store: PersonItemStore) {
        super();
        this.store = store;
        this._orderBy = "sorted";
        this._order = "descending";

        this.creator = new JobCreatorStore(this.store.id, async () => {
            const result_ = await new PersonsApi(getApiConfiguration()).getPersonById({id: this.store.id});
            this.store.update(result_);
            this.store.updateState();
        });

        makeObservable(this, {
            current: observable,
            typeDepartmentList: observable,
            deleteDialogState: observable
        });
    }

    async getTypeDepartmentList(): Promise<void> {
        this.typeDepartmentList = (await new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({
            parentId: JurisdictionData.organizationTypes.depsId,
            queryType: 'tree',
            size: 100,
            showOnlyStructural: true
        })).map(x => x.id)
    }

    setPrimary(id: string) {
        if (this.store.primaryJob.value !== id) {
            this.store.primaryJob.value = id;
            this.store.save()
        }
    }

    async request(): Promise<PagedItems<JobItemStore>> {
        let filters: any = {
            page: this.page + 1,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this.store.id,
            organizationIds: this.organizationFilter.value?.length ? this.organizationFilter.value.map(x => x.id) : undefined,
            postIds: this.postFilter?.value.length ? this.postFilter.value.map(x => x.id) : undefined,
            statusIds: this.statusFilter?.value.filter(x => x.id !== PersonStatusesNew.Double).length ? this.statusFilter
                .value.filter(x => x.id !== PersonStatusesNew.Double).map(x => x.id) : [0, 1, 2, 3],
            tags: this.tagsFilter.value?.length ? this.tagsFilter.value.map(x => x.id) : undefined,
            states: [0, 1]
        };
        const api: JobsApi = new JobsApi(getApiConfiguration());
        const count = await api.getAllPersonJobsCount(filters);
        const newList: JobItemStore[] = [];
        try {
            await this.getTypeDepartmentList();
            const result = await api.getAllPersonJobs(filters);
            for (const item of result) {
                newList.push(new JobItemStore(this, item))
            }
        } finally {

        }
        return new PagedItems<JobItemStore>(newList, count.count)
    };
}
