import { Box } from "@mui/material";
import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { AuthorizePermission, CreateButtonsGroup } from '../../../../components';
import { DefaultFormModalStore } from "../../../../components/shared/DefaultFormModalStore";
import { DefaultMultiSelectStore } from "../../../../components/shared/DefaultMultiSelectStore";
import { DefaultValueStore } from "../../../../components/shared/DefaultValueStore";
import { DefaultMultiSelect } from "../../../../components/shared/components/DefaultMultiSelect";
import { DefaultValue } from "../../../../components/shared/components/DefaultValue";
import { AllPermissions } from '../../../../core/const';
import { PermissionDto } from "../../../../services/auth";
import { UserDto } from "../../../../services/management";
import { StoreProps } from "../../../../stores";

export const RoleCreator = observer(({ store }: StoreProps<DefaultFormModalStore<{
    name: DefaultValueStore<string>,
    description: DefaultValueStore<string>,
    users: DefaultMultiSelectStore<UserDto>,
    permissions: DefaultMultiSelectStore<PermissionDto>
}>>) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ p: 2 }}>
            <DefaultValue
                store={store.fields.name}
                title={t('common.name')} />
            <DefaultMultiSelect
                store={store.fields.permissions}
                nameExp={'name'}
                valueExp={'id'}
                title={t('usersPage.roles.create.permissions')} />
            <DefaultMultiSelect
                store={store.fields.users}
                nameExp={"username"}
                valueExp={'id'}
                title={t('usersPage.roles.create.users')} />
            <DefaultValue
                store={store.fields.description}
                multiline
                rows={4}
                InputProps={{
                    sx: {
                        height: '100%'
                    }
                }}
                style={{ width: '100%' }}
                title={t('usersPage.roles.create.description')} />
            <AuthorizePermission permissions={AllPermissions.user.create}>
                <CreateButtonsGroup onCancel={() => store.Open = false}
                    onSave={async () => await store.submit()}
                    isCancelBtnDisabled={store.processing}
                    isSaveBtnDisabled={store.processing || !store.valid}
                    testid="newRoleCreateForm" />
            </AuthorizePermission>
        </Box>
    );
});
