import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {FormRoot} from "../../../../components";
import {Box, List, Tooltip} from "@mui/material";
import {DcrListItem} from "../../DcrListItem";
import {RequestDetailsStore} from "../../../../stores/management/dcr/requests/RequestDetailsStore";
import {StoreProps} from "../../../../stores";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const FieldNameValuesComponent = observer(({store}: StoreProps<RequestDetailsStore>) => {
    const {t} = useTranslation();
    return (
        <FormRoot>
            {
                !store?.loading ?
                    (
                        <List style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: "wrap",
                            alignItems: 'flex-start',
                            padding: 'unset'
                        }}>
                            {
                                store.firstRequestFields.filter(x => !!x.value || !!x.newName).map((x, k) => (
                                    <Box sx={{display: 'flex', flexDirection: 'row', width: '32%'}}>
                                        <DcrListItem
                                            key={k}
                                            name={t('dcrPage.fields.' + x.field)}
                                        >
                                            <Box sx={{display: 'flex'}}>
                                                <Box>{store.getFirstValueName(x.field!)}</Box>
                                                {
                                                    x.type != 2 && x.value &&
                                                    <Tooltip
                                                        sx={{ml: '8px'}}
                                                        title={t("common.id") + ": " + x.value}>
                                                        <InfoOutlinedIcon/>
                                                    </Tooltip>
                                                }
                                            </Box>

                                        </DcrListItem>
                                    </Box>
                                ))
                            }
                        </List>
                    )
                    : <Box sx={{pt: 2}}>{t("common.download")}...</Box>
            }
        </FormRoot>
    );
});
