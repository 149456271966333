import { Box } from "@mui/material";
import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { AuthorizePermission, CreateButtonsGroup } from '../../../../components';
import { DefaultFormModalStore } from "../../../../components/shared/DefaultFormModalStore";
import { DefaultMultiSelectStore } from "../../../../components/shared/DefaultMultiSelectStore";
import { DefaultSelectStore } from "../../../../components/shared/DefaultSelectStore";
import { DefaultValueStore } from "../../../../components/shared/DefaultValueStore";
import { DefaultMultiSelect } from '../../../../components/shared/components/DefaultMultiSelect';
import { DefaultSelect } from '../../../../components/shared/components/DefaultSelect';
import { DefaultValue } from "../../../../components/shared/components/DefaultValue";
import { AllPermissions } from '../../../../core/const';
import { UserDto } from "../../../../services/management";
import { StoreProps } from "../../../../stores";

export const GroupCreator = observer(({ store }: StoreProps<DefaultFormModalStore<{
    name: DefaultValueStore<string>,
    owner: DefaultSelectStore<UserDto>,
    participants: DefaultMultiSelectStore<UserDto>
}>>) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ height: 65 }}>
                <DefaultValue
                    testid="newDCRGroupCreateForm-groupName"
                    style={{ width: '100%' }}
                    store={store.fields.name}
                    title={t('usersPage.groupName')}
                    disabled={store.processing}
                />

            </Box>
            <Box sx={{ mt: 1 }}>
                <DefaultSelect title={t('usersPage.owner')} nameExp='username'
                    store={store.fields.owner} testid="newDCRGroupCreateForm-owner" hasLabel />
            </Box>
            <DefaultMultiSelect store={store.fields.participants} nameExp='username' title={t('usersPage.participants')}
                style={{
                    width: '100%',
                }}
                testid="newDCRGroupCreateForm-participants" />

            <AuthorizePermission permissions={AllPermissions.user.create}>
                <CreateButtonsGroup onCancel={() => store.Open = false}
                    isCancelBtnDisabled={store.processing}
                    onSave={async () => await store.submit()}
                    isSaveBtnDisabled={store.processing || !store.valid}
                    testid="newDCRGroupCreateForm" />
            </AuthorizePermission>
        </Box>
    );
});
