import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {Page, PageTab, PageTabs} from "../../components";
import {ClientParametersTab} from "./tabs/ParametersTab";
import {ClientItemStore} from "../../stores/management/clients/ClientItemStore";
import {JurisdictionsTab} from "./client/tabs/JurisdictionsTab";
import {PersonParams} from "../../icons/personParams";
import {OfficeBuilding} from "../../icons/office-building";

interface Props {
    store: ClientItemStore;
    style?: React.CSSProperties;
}

export const ClientDetails = observer(({store, style}: Props) => {
    const {t} = useTranslation();


    const renderTabs = (index: number) => {
        switch (index) {
            case 0:
                return <ClientParametersTab store={store.clientDetailsVM} style={{flex: 1}}/>;
            case 1:
                return <JurisdictionsTab store={store.clientStore.jurisdictionsStore} style={{flex: 1}}/>;
            default:
                return null;
        }
    };


    return (
        <Page
            style={style}
        >
            <PageTabs
                index={store.tabIndex}
                onChange={t => store.tabIndex = t}>
                <PageTab icon={<PersonParams/>} title={t('clientPage.tabs.parameters')}/>
                <PageTab icon={<OfficeBuilding/>} title={t('clientPage.tabs.jurisdictions')}/>
            </PageTabs>
            <div style={{marginTop: 4, marginBottom: 4, padding: 20}}>
                {renderTabs(store.tabIndex)}
            </div>
        </Page>
    );
});
