import {InputAdornment, TextField} from '@mui/material';
import {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Search as SearchIcon} from '../../icons/search';

export interface SearchProps {
    value?: string | null
    onChange?: (e: string) => void
    placeholder?: string
    style?: React.CSSProperties
    testid?: string
}

export const Search = ({value = "", onChange, placeholder, style, testid}: SearchProps) => {
    const [text, setText] = useState(value);
    const {t} = useTranslation();


    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (text === value) {
                return;
            }
            if (onChange) {
                onChange(text ?? "");
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [text]);

    useEffect(() => {
        if (!value) setText('')
    }, [value])

    const handleText = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        if (placeholder == 'External Id') {
            const re = /^[0-9\b]+$/;
            e.preventDefault();
            if (e.target.value === '' || re.test(e.target.value)) {
                setText(e.target.value);
            }
        }
        else {
            e.preventDefault();
            setText(e.target.value);
        }
    };

    return (
        <TextField
            size="small"
            style={{
                ...style
            }}
            value={text}
            onChange={handleText}
            inputProps={{"data-testid":`${testid}-search`}}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="small"/>
                    </InputAdornment>
                ),
                sx: {
                    py: 0.5,
                    px: 1,
                    fieldset: {
                        height: 48,
                    },
                }
                // sx: {
                //     input: {
                //         fontSize: '1rem',
                //         fontWeight: 400,
                //         lineHeight: 1.5
                //     }
                // }
            }}
            label={placeholder ? placeholder : t('common.search')}
            placeholder={placeholder ? placeholder : t('common.search')}
        />
    );
};
