import {makeAutoObservable} from 'mobx';
import {OrganizationItemStore} from "../OrganizationItemStore";
import {AddressUniqueDto, AddressUniquesApi, OrganizationsApi,} from "../../../../services/management";
import {getApiConfiguration, ILoad, IProcessing} from "../../../../core";
import {AddressDto, DefaultAddressesStore} from "../../../../shared/address/DefaultAddressStore";
import {JurisdictionLocalSetting} from "../../../authorization/AuthorizationStore";

export class OrganizationAddressesStore implements IProcessing, ILoad {
    readonly _store: OrganizationItemStore;
    dto: AddressUniqueDto;
    loading: boolean = false;
    processing: boolean = false;
    addressStore: DefaultAddressesStore;

    constructor(store: OrganizationItemStore, dto: AddressUniqueDto) {
        this._store = store;
        this.dto = dto;
        this.addressStore = new DefaultAddressesStore(this.dto as AddressDto, this);
        makeAutoObservable(this);
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this._store._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async getAddressId(isCustomCoordinates: boolean): Promise<AddressUniqueDto | undefined> {
        const api: AddressUniquesApi = new AddressUniquesApi(getApiConfiguration());
        try {
            return (await api.setAddressUnique({
                setAddressUniqueDto: {
                    localityId: this.addressStore.locality?.currentLocality?.id!,
                    building: this.addressStore.building.value,
                    house: this.addressStore.house.value!,
                    zip: this.addressStore.zip.value!,
                    okrugId: this.addressStore.okrug.value?.id,
                    districtId: this.addressStore.district.value?.id,
                    latitude: isCustomCoordinates ? this.addressStore.latitude.value!.replace(/\./g, ',') : "11",
                    longtitude: isCustomCoordinates ? this.addressStore.longtitude.value!.replace(/\./g, ',') : "22",
                    gpsAccuracy: "123",
                    entryTypeId: this.addressStore.entryType.valueId,
                    entryValue: this.addressStore.entryValue.value
                },
                isCustomCoordinates: isCustomCoordinates
            }))[0];
        } finally {
        }
    }

    async submit(): Promise<void> {
        this.processing = true;
        try {
            await this.addressStore.validate()
            if (!this.addressStore.valid) return
            let address = await this.getAddressId(!!this.addressStore.isCustomCoordinates.value);
            if (!address) {
                this.addressStore.reset();
                return
            }
            await this.updateOrganizations(address?.id!);
        } finally {
            this.processing = false;
        }
    }

    async updateOrganizations(addressId: string): Promise<void> {
        try {
            let item = await new OrganizationsApi(getApiConfiguration()).updateOrganization({
                id: this._store._dto.id,
                updateOrganizationDto: {
                    itn: this._store._dto.itn.itn,
                    parentId: this._store._dto.parentId,
                    typeOrgId: this._store._dto.typeOrgId,
                    formPropertyId: this._store._dto.formPropertyId,
                    statusId: this._store._dto.statusId,
                    addressUniqueId: addressId
                }
            })
            this.addressStore.lazyUpdate()
            this.dto.id = item.addressUniqueId;
        } finally {
        }
    }

    async updateCoordinates(): Promise<void> {
        this.processing = true;
        try {
            let address = await this.getAddressId(false);
            if (!address) return
            await this.updateOrganizations(address?.id!);
        } finally {
            this.processing = false;
        }
    }

    async pull() {
        this.loading = true;
        const api: AddressUniquesApi = new AddressUniquesApi(getApiConfiguration());
        try {
            const result = await api.getAddressUniqueById({id: this.dto?.id});
            this.update(result)
        } finally {
            this.loading = false;
        }
    }

    update(dto: AddressUniqueDto) {
        this.dto = dto;
        this.addressStore.update(this.dto as AddressDto)
    }
}
