import {observer} from 'mobx-react';
import React from "react";
import {DefaultSelect} from '../../../../components/shared/components/DefaultSelect';
import {useTranslation} from "react-i18next";
import {Box} from '@mui/material';
import {FirstNameDto, GenderDto, MiddleNameDto, SurnameDto,} from '../../../../services/management';
import {DefaultFormStore} from "../../../../components/shared/DefaultFormStore";
import {DefaultSelectStore} from "../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../components/shared/DefaultValueStore";
import {DefaultCheckbox} from "../../../../components/shared/components/DefaultCheckbox";

interface Props {
    store: DefaultFormStore<{
        name: DefaultSelectStore<FirstNameDto>,
        middleName: DefaultSelectStore<MiddleNameDto>,
        surname: DefaultSelectStore<SurnameDto>,
        hasNoMiddleName: DefaultValueStore<boolean>,
        gender: DefaultSelectStore<GenderDto>
    }>;
}

export const PropertyCreator = observer(({store}: Props) => {
    const {t} = useTranslation();

    return (
        <Box sx={{p: 2}}>
            <DefaultSelect store={store.fields.surname}
                           title={t('table.surname')}
                           hasLabel
                           style={{marginBottom: 10}}
                           testid="createPersonForm-surname"
            />
            <DefaultSelect store={store.fields.name}
                           title={t('table.name')}
                           style={{marginBottom: 10}}
                           hasLabel
                           testid="createPersonForm-name"
            />
            <DefaultSelect store={store.fields.middleName}
                           title={t('table.middleName')}
                           style={{marginBottom: 10}}
                           disabled={!!store.fields.hasNoMiddleName.value}
                           hasLabel
                           testid="createPersonForm-middleName"
            />
            <DefaultCheckbox store={store.fields.hasNoMiddleName}
                             title={t('common.emptyMiddleName')}/>


            <DefaultSelect store={store.fields.gender}
                           title={t('table.gender')}
                           style={{marginBottom: 20}}
                           hasLabel
                           testid="createPersonForm-gender"
            />
        </Box>
    );
});
