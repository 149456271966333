import {observer} from 'mobx-react';
import {Autocomplete, Checkbox, Chip, Container, Popper, TextField} from '@mui/material';
import React, {useCallback} from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {getValueInObject} from "../../../core/getValueInObject";
import {useTranslation} from "react-i18next";
import {useRootStore} from "../../../stores";
import {DefaultMultiSelectStore} from "../DefaultMultiSelectStore";

interface Props<T> {
    store: DefaultMultiSelectStore<T>;
    title: string;
    style?: React.CSSProperties;
    nameExp?: string | ((dto: T) => string);
    valueExp?: string | null;
    zIndex?: number;
    disableCloseOnSelect?: boolean;
    disableUnderline?: boolean;
    testid?: string;
    tags?: boolean;
}

export const DefaultMultiSelect = observer(({
                                                store,
                                                title,
                                                style,
                                                nameExp = 'nameRus',
                                                valueExp = 'id',
                                                zIndex = 1300,
                                                disableCloseOnSelect = true,
                                                disableUnderline = true,
                                                testid,
                                                tags = false
                                            }: Props<any>) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;
    const {t} = useTranslation();
    const {themeColorStore} = useRootStore();
    const divider = themeColorStore.themeSettings === 'dark' ? '#2D3748' : '#E6E8F0';

    const renderInput = useCallback((params: any) => (
        <TextField
            // disablePortal={true}
            {...params}
            // size="small"
            margin="normal"
            placeholder={`${title} ${store.value.length ? `(${store.value.length})` : ''}`}
            label={`${title} ${store.value.length ? `(${store.value.length})` : ''}`}
            InputLabelProps={{
                sx: {
                    // color: 'red',
                    // height: '45px',
                    top: -5
                }
            }}
            InputProps={{
                ...params.InputProps,
                ...{'data-testid': `${testid}-multiselect`},
                // height: 48,
                disableUnderline: disableUnderline,
                sx: {
                    fieldset: {
                        height: 48,
                        // padding: 0,
                    },
                    input: {
                        // backgroundColor: 'lightcyan',
                        // opacity: 0.5,
                        // height: '48px',
                        padding: 0,
                        marginTop: -1,
                    }
                }
            }}
            autoComplete='off'
        />
    ), [title, store.value])

    const renderOption = useCallback((props: any, option: any, {selected}: any) => {
          return  <li {...props} {...{'data-testid': `${testid}-selectList-multiselect`}}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{marginRight: 8}}
                    checked={selected}
                />
              <p style={{margin: 0}} {...{'data-testid': `${testid}-selectText-multiselect`}}>{getValueInObject(option, nameExp) + (option.isInactive ? ` (${t("common.noActive")})` : "")}</p>
            </li>

    }, []);

    const getItems = useCallback(() => {
        return store?.items.filter(x => !!getValueInObject(x, nameExp))
    }, [store?.items]);

    const PopperComponent = useCallback(({style, ...props}: any) => (
        <Popper
            {...props}
            placement="bottom"
            style={{...style, zIndex: zIndex, top: 40}}
        />
    ), []);

    const loading = store.loading;

    return (
        <>
            <Autocomplete
                options={getItems()}
                noOptionsText={t(loading ? "common.download" : "ui.noOptions")}
                getOptionLabel={x => getValueInObject(x, nameExp) + (x.isInactive ? ` (${t("common.noActive")})` : "")}
                clearOnBlur
                autoComplete
                multiple
                PopperComponent={PopperComponent}
                defaultValue={store.value}
                disableCloseOnSelect={disableCloseOnSelect}
                value={store.value}
                style={{...{width: 'auto', minWidth: 250}, ...style}}
                loading={store.loading}
                loadingText={t("common.download")}
                isOptionEqualToValue={(option, value) => valueExp ? option[valueExp] === value[valueExp] : option === value}
                onClose={() => {
                    store.items = []
                    store.setSearch("")
                }}
                onInputChange={(e, t) => {
                    if (e?.type === 'change')
                        store.setSearch(t)
                }}
                onOpen={async () => {
                    store.items = []
                    await store.pull()
                }}
                onChange={(event, newValue) => {
                    store.value = newValue
                }}
                renderTags={() => null}
                renderOption={renderOption}
                renderInput={renderInput}
                ListboxProps={{
                    style: {
                        maxHeight: '30vh',
                        borderColor: divider,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderRadius: 8,
                    }
                }}
            />

            {
                tags &&
                <>
                    <Container maxWidth="xl"
                               style={{display: 'flex', flexWrap: 'wrap', marginLeft: 15, marginTop: 10, padding: 0, marginBottom: 15}}>
                        {store.value.map((item) => {
                            return <Chip key={item.id} label={getValueInObject(item, nameExp)} size="medium" onDelete={() => {
                                store.value = store.value.filter(x => x[valueExp!] != item[valueExp!])
                            }} style={{marginRight: 15, padding: 0, marginBottom: 6}}/>
                        })}
                    </Container>
                </>
            }
        </>
    );
});
