import {DefaultSelectStore} from "../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../components/shared/DefaultValueStore";

export enum RequestStatusEnum {
    Verified = 3,
    Double = 7,
    OnVerification = 2,
    WaitingVerification = 1,
    Decline = 4,
    Partialy = 8
}

export enum RequestTypeEnum {
    Organization = 0,
    Person = 1,
}

export enum ResponseStatusEnum {
    New,
    Sended,
    Error
}

export enum DCRStatusEnum {
    Create = 0, Update = 1
}

export enum RequestFieldTypeEnum {
    External,
    Data360,
    String
}

export enum FiledTypeEnum {
    Additional,
    General
}

export enum DoubleEnum {
    NotDouble = "",
    Double = "1"
}

export enum RequestCorrect {
    All = -1, Yes = 1, No = 0,
}

export type AddressField = "region" |  "city" |  "street" |  "streettype" | "building"
export type AdditionalAddressField = "postcode" | "gps_la" | "gps_lo" | "district" | "okrug";


export type PersonField =
    "lastname" |
    "firstname" |
    "middlename" |
    "withoutMiddlename" |
    "sex" |
    // "phone1" |
    "company" |
    // "owner" |
    "position" |
    "specialization" |
    "archivereason" |
    // "city" |
    // "street" |
    // "building" |
    // "region" |
    // "postcode" |
    // "gps_la" |
    // "gps_lo" |
    // "companyMorionId" |
    // "phoneCategory" |
    // "phoneStatus" |
    // "phoneType" |
    "companyId" |
    "originalId" |
    "isDouble" |
    "isPrimary" |
    "isPrimarySpeciality" |
    "primaryJob"

export type OrganizationField =
    "name" |
    "companytype" |
    "cliniccategory" |
    "archivereason" |
    // "brand" |
    // "reregistrations" |
    // "movement" |
    // "tradeformat" |
    // "main" |
    "phone1" |
    "phonecategory" |
    "phonestatus" |
    "phonetype" |
    // "companyMorionId" |
    //"companyId" |
    "originalId" |
    "isDouble" |
    "code" |
    "parent" |
    "additionalNumber" |
    AddressField |
    AdditionalAddressField

export type OrganizationFieldCustom = "address"
export type PersonFieldCustom = "status" | "job"



export type DcrPersonBlockInfo =
    "main" |
    "specialization" |
    "job" |
    "double"

export type DcrOrganizationBlockInfo =
    "main" |
    "address" |
    "phone" |
    "double"

export interface BaseField {
    name: string;
}

export interface ResponseField extends BaseField{
    value: string
}

export interface RequestField extends BaseField {
    store: DefaultSelectStore<any> | DefaultValueStore<string> | DefaultValueStore<boolean>;
    readonly?: boolean;
    isAdditional: boolean;
    field: PersonField | OrganizationField;
    id: string;
    isHide: boolean;
    ignore?: boolean;
    link?: string;
}
