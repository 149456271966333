import { makeObservable, observable } from 'mobx';
import { OrganizationItemStore } from '../../../index';
import { getApiConfiguration, getObject, setObject } from '../../../../../core';
import { OrganizationDto, OrganizationWebsitesApi, WebsiteStatusDto, OrganizationWebsiteStatusesApi } from '../../../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../utils';
import { DefaultSelectStore } from "../../../../../components/shared/DefaultSelectStore";
import { DefaultValueStore } from "../../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import { OrganizationWebsiteItemStore } from './OrganizationWebsiteItemStore';
import {DefaultFormModalStore} from "../../../../../components/shared/DefaultFormModalStore";

export class OrganizationWebsiteTableStore extends TableStore<OrganizationWebsiteItemStore> {
    _orgStore: OrganizationItemStore
    _dto: OrganizationDto
    organizationId: string

    current: OrganizationWebsiteItemStore | undefined | null;
    readonly creator: DefaultFormModalStore<{
        websiteAddress: DefaultValueStore<string>,
        websiteStatus: DefaultSelectStore<WebsiteStatusDto>,
    }> = new DefaultFormModalStore({
            websiteAddress: new DefaultValueStore<string>("",
                (value) => new RegExp(/^((https?|ftp|smtp):\/\/)?(www.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/).test(value ?? "") ? "" : i18n.t('errors.website')),
            websiteStatus: new DefaultSelectStore<WebsiteStatusDto>(
                null, (x) => new OrganizationWebsiteStatusesApi(getApiConfiguration()).getOrganizationWebsiteStatuses(x),
                (value) => !value ? i18n.t('errors.empty') : ""),
        },

        (model) => this.create(model));

    constructor(orgStore: OrganizationItemStore, dto: OrganizationDto) {
        super();
        this._orgStore = orgStore
        this._dto = dto
        this.organizationId = this._dto.id

        const filters = getObject<TableLocalFilters<OrganizationWebsiteItemStore>>('organizations-website-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            organizationId: observable,
            current: observable,
        });
    }


    async request(): Promise<PagedItems<OrganizationWebsiteItemStore>> {
        const countFilters = {
            organizationId: this._dto.id,
        }
        const api: OrganizationWebsitesApi = new OrganizationWebsitesApi(getApiConfiguration());
        const count = await api.getOrganizationWebsitesCount(countFilters);

        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            organizationId: this.organizationId,
        };
        const result = await api.getOrganizationWebsites(filters);

        const newList: OrganizationWebsiteItemStore[] = [];
        for (const item of result) {
            newList.push(new OrganizationWebsiteItemStore(this, item))
        }

        return new PagedItems<OrganizationWebsiteItemStore>(newList, count.count)
    };

    async create(model: any): Promise<void> {
        this.processing = true;
        const api: OrganizationWebsitesApi = new OrganizationWebsitesApi(getApiConfiguration());
        try {
            const result = await api.setOrganizationWebsites({
                organizationId: this._dto.id,
                setOrganizationWebsiteDto: [
                    {
                        organizationId: this._dto.id,
                        websiteAddress: this.creator.fields.websiteAddress.value!,
                        websiteStatusId: this.creator.fields.websiteStatus.value?.id!,
                        description: ''
                    }
                ]
            });
            await this.pull()
        } finally {
            this.processing = false;
        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<OrganizationWebsiteItemStore>>('organizations-website-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
