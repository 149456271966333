import {Box, Card, Table, TableBody, TableHead, TableRow, Tooltip, Typography} from "@mui/material";
import {observer} from "mobx-react";
import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Cell,
    ConfirmationForm,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    PaginationLocal,
    Progress,
    Search
} from "../../../../components";
import {CopyToClipboard} from "../../../../components/CopyToClipboard";
import {MenuComponent} from "../../../../components/MenuComponent";
import {MenuItemComponent} from "../../../../components/MenuItemComponent";
import {NavLink} from "../../../../components/NavLink";
import {SeverityPill, SeverityPillColor} from "../../../../components/severity-pill";
import {DeleteIcon} from "../../../../icons/deleteIcon";
import {Scrollbar} from "../../../../scrollbar";
import {
    OrganizationEmployeesTableStore
} from "../../../../stores/management/organizations/details/employees/OrganizationEmployeesTableStore";
import {personStatusHandler} from "../../../persons";
import {OrganizationEmployeeDetails} from "./OrganizationEmployeeDetails";

interface Props {
    store: OrganizationEmployeesTableStore;
    double?: boolean;
    style?: React.CSSProperties
}

export const OrganizationEmployeesTable = observer(({ store, double = false, style }: Props) => {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    useEffect(() => {
        if (!!double) {
            store.size = 10;
        } else {
            store.size = 25;
        }
    }, []);

    const handleOpenDetails = (openId: string): void => {
        setOpenDetails((prevValue) => (prevValue === openId ? null : openId));
    };

    if (!!double) {
        return (
            <>
                <Card>
                    <Scrollbar>
                        <Table sx={{ minWidth: 900 }}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='2%' testid="organizationEmployeesTable-expand"></HCell>
                                    <HCell orderBy='id' store={store} width='10%' testid="organizationEmployeesTable-Id">Id</HCell>
                                    <HCell orderBy='surname' store={store} testid="organizationEmployeesTable-surname">{t('table.surname')}</HCell>
                                    <HCell orderBy='firstName' store={store} testid="organizationEmployeesTable-firstName">{t('table.name')}</HCell>
                                    <HCell orderBy='middleName' store={store} testid="organizationEmployeesTable-middleName">{t('table.middleName')}</HCell>
                                    <HCell orderBy='gender' store={store} testid="organizationEmployeesTable-gender">{t('table.gender')}</HCell>
                                    <HCell orderBy='post' store={store} testid="organizationEmployeesTable-post">{t('table.post')}</HCell>
                                    <HCell orderBy='status' store={store} testid="organizationEmployeesTable-status">{t('table.status')}</HCell>
                                    <HCell orderBy='modified' store={store} style={{ width: '15%' }} testid="organizationEmployeesTable-modified">{t('table.modified')}</HCell>
                                    <HCell width='2%' testid="organizationEmployeesTable-action"></HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    store.items.length > 0
                                        ? store.items.map(t => {
                                            const open = t.dto.id === openDetails;
                                            return (
                                                <Fragment key={t.dto.id}>
                                                    <TableRow
                                                        hover
                                                        key={t.dto.id}
                                                        style={{ width: '100%' }}
                                                        onClick={() => store.current = t}
                                                    >
                                                        <ExpandButton item={t} isOpen={open} testid="jobs"
                                                            onClick={async () => {
                                                                handleOpenDetails(t.dto.id);
                                                            }}
                                                        />
                                                        <Cell testid="organizationTable-id">
                                                            <Typography variant="subtitle2"
                                                                style={{ display: "flex", alignItems: 'center' }}>
                                                                {`${t.dto.person.id.substring(0, 11)}...`}
                                                                <CopyToClipboard copyText={t.dto.person.id} />
                                                            </Typography>
                                                        </Cell>
                                                        <Cell titleText={t.dto.person.surname?.nameRus} testid="organizationEmployeesTable-surname">
                                                            <NavLink to={`/persons/${t.dto.person.id}`} target="_blank">
                                                                <Typography
                                                                    flexWrap={"wrap"}
                                                                    variant="body2">
                                                                    {t.dto.person.surname?.nameRus}
                                                                </Typography>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell testid="organizationEmployeesTable-firstName">
                                                            <NavLink to={`/persons/${t.dto.person.id}`} target="_blank">
                                                                <Typography
                                                                    variant="body2">
                                                                    {t.dto.person.firstName?.nameRus}
                                                                </Typography>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell testid="organizationEmployeesTable-middleName">
                                                            <NavLink to={`/persons/${t.dto.person.id}`} target="_blank">
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ alignItems: 'center', display: "flex" }}>
                                                                    {t.dto.person.middleName?.nameRus}
                                                                </Typography>
                                                            </NavLink>
                                                        </Cell>
                                                        <Cell testid="organizationEmployeesTable-gender">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2"
                                                                style={{ alignItems: 'center', display: "flex" }}
                                                            >
                                                                {t.dto.person.gender?.nameRus}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="organizationEmployeesTable-post">
                                                            <Typography
                                                                color="textSecondary"
                                                                variant="body2"
                                                                style={{ alignItems: 'center', display: "flex" }}
                                                            >
                                                                {t.dto.post?.nameRus}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell testid="organizationEmployeesTable-status">
                                                            <SeverityPill
                                                                color={personStatusHandler(t.dto.status?.nameRus || '') as SeverityPillColor}>
                                                                <Tooltip
                                                                    title={t.dto.status?.nameRus}>
                                                                    <Typography variant="caption" align={'left'} sx={{
                                                                        maxWidth: 130,
                                                                        textOverflow: 'ellipsis',
                                                                        overflow: 'hidden',
                                                                    }}>
                                                                        {t.dto.status?.nameRus}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </SeverityPill>
                                                        </Cell>
                                                        <Cell testid="organizationEmployeesTable-modified">
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {t.modifiedAt.toString()}
                                                            </Typography>
                                                        </Cell>
                                                        <Cell />
                                                    </TableRow>
                                                    {open && (
                                                        <ExpandRow key={`${t.dto.id}_`} item={t}>
                                                            <OrganizationEmployeeDetails
                                                                store={t}
                                                            />
                                                        </ExpandRow >)
                                                    }
                                                </Fragment>)
                                        })
                                        : <Cell colSpan={10}>
                                            <EmptyBox />
                                        </Cell>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
                {store.items.length > 0 && <PaginationLocal store={store} />}
                {
                    !store.items.length && !!store.loading && <Box
                        sx={{
                            flexGrow: 1,
                            py: 3,
                        }}
                    >
                        <Progress store={store} />
                    </Box>
                }
            </>
        );
    }
    return (
        <>
            {
                store.current
                    ? (
                        <ExpertDialog
                            title='Delete'
                            open={store.current.deleteDialogState}
                            onClose={() => store.current ? store.current.deleteDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                onSubmit={async () => await store.current?.delete()}
                                onClose={() => store.current ? store.current.deleteDialogState = false : false}
                            >
                                {t('common.deleteConfirm')} "{store.current.dto.person.firstName.nameRus}"?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Box
                sx={{ my: 2 }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <Search
                    testid="organizationEmployeesTable-searchSurname"
                    placeholder={t('common.searchSurname')}
                    value={store.surname.value}
                    onChange={t => store.surname.value = t}
                    style={{ width: '32%' }}
                />

                <Search
                    testid="organizationEmployeesTable-searchName"
                    placeholder={t('common.searchName')}
                    value={store.name.value}
                    onChange={t => store.name.value = t}
                    style={{ width: '32%' }}
                />

                <Search
                    testid="organizationEmployeesTable-searchMiddleName"
                    placeholder={t('common.searchMiddleName')}
                    value={store.middleName.value}
                    onChange={t => store.middleName.value = t}
                    style={{ width: '32%' }}
                />
            </Box>
            <Card>
                <Scrollbar>
                    <Table sx={{ minWidth: 1050 }}>
                        <TableHead>
                            <TableRow>
                                <HCell width='3%' testid="organizationEmployeesTable-expand"></HCell>
                                <HCell orderBy='id' store={store} width='10%' testid="organizationEmployeesTable-Id">Id</HCell>
                                <HCell orderBy='surname' store={store} testid="organizationEmployeesTable-surname">{t('table.surname')}</HCell>
                                <HCell orderBy='firstName' store={store} testid="organizationEmployeesTable-firstName">{t('table.name')}</HCell>
                                <HCell orderBy='middleName' store={store} testid="organizationEmployeesTable-middleName">{t('table.middleName')}</HCell>
                                <HCell orderBy='gender' store={store} testid="organizationEmployeesTable-gender">{t('table.gender')}</HCell>
                                <HCell orderBy='post' store={store} testid="organizationEmployeesTable-post">{t('table.post')}</HCell>
                                <HCell orderBy='status' store={store} testid="organizationEmployeesTable-status">{t('table.status')}</HCell>
                                <HCell orderBy='modified' store={store} style={{ width: '15%' }} testid="organizationEmployeesTable-modified">{t('table.modified')}</HCell>
                                <HCell style={{ padding: '0 15px' }} testid="organizationEmployeesTable-action">{t('table.action')}</HCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                store.items.length > 0
                                    ? store.items.map(item => {
                                        const open = item.dto.id === openDetails;
                                        return (
                                            <Fragment key={item.dto.id}>
                                                <TableRow
                                                    hover
                                                    key={item.dto.id}
                                                    style={{ width: '100%' }}
                                                    onClick={() => store.current = item}
                                                >
                                                    <ExpandButton item={item} isOpen={open} testid="jobs"
                                                        onClick={async () => {
                                                            handleOpenDetails(item.dto.id);
                                                        }}
                                                    />
                                                    {/* <Cell>
                                                        <Box sx={{ maxWidth: '2px', minHeight: 48 }} />
                                                    </Cell> */}
                                                    <Cell testid="organizationTable-id">
                                                        <Typography variant="subtitle2"
                                                            style={{ display: "flex", alignItems: 'center' }}>
                                                            {`${item.dto.person.id.substring(0, 11)}...`}
                                                            <CopyToClipboard copyText={item.dto.person.id} />
                                                        </Typography>
                                                    </Cell>
                                                    <Cell titleText={item.dto.person.surname?.nameRus} testid="organizationEmployeesTable-surname">
                                                        <NavLink to={`/persons/${item.dto.person.id}`} target="_blank">
                                                            <Typography
                                                                flexWrap={"wrap"}
                                                                variant="body2">
                                                                {item.dto.person.surname?.nameRus}
                                                            </Typography>
                                                        </NavLink>
                                                    </Cell>
                                                    <Cell testid="organizationEmployeesTable-firstName">
                                                        <NavLink to={`/persons/${item.dto.person.id}`} target="_blank">
                                                            <Typography
                                                                variant="body2">
                                                                {item.dto.person.firstName?.nameRus}
                                                            </Typography>
                                                        </NavLink>
                                                    </Cell>
                                                    <Cell testid="organizationEmployeesTable-middleName">
                                                        <NavLink to={`/persons/${item.dto.person.id}`} target="_blank">
                                                            <Typography
                                                                variant="body2"
                                                                style={{ alignItems: 'center', display: "flex" }}>
                                                                {item.dto.person.middleName?.nameRus}
                                                            </Typography>
                                                        </NavLink>
                                                    </Cell>
                                                    <Cell testid="organizationEmployeesTable-gender">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2"
                                                            style={{ alignItems: 'center', display: "flex" }}
                                                        >
                                                            {item.dto.person.gender?.nameRus}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell testid="organizationEmployeesTable-post">
                                                        <Typography
                                                            color="textSecondary"
                                                            variant="body2"
                                                            style={{ alignItems: 'center', display: "flex" }}
                                                        >
                                                            {item.dto.post?.nameRus}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell testid="organizationEmployeesTable-status">
                                                        <SeverityPill
                                                            color={personStatusHandler(item.dto.status?.nameRus || '') as SeverityPillColor}>
                                                            <Tooltip
                                                                title={item.dto.status?.nameRus ? item.dto.status?.nameRus : 'Работает'}>
                                                                <Typography variant="caption" align={'left'} sx={{
                                                                    maxWidth: 130,
                                                                    textOverflow: 'ellipsis',
                                                                    overflow: 'hidden',
                                                                }}>
                                                                    {item.dto.status?.nameRus ? item.dto.status?.nameRus : 'Работает'}
                                                                </Typography>
                                                            </Tooltip>
                                                        </SeverityPill>
                                                    </Cell>
                                                    <Cell testid="organizationEmployeesTable-modified">
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {item.modifiedAt.toString()}
                                                        </Typography>
                                                    </Cell>
                                                    <Cell align={'center'} testid="organizationEmployeesTable-action">
                                                        {store.items.length != 1 &&

                                                            <MenuComponent>
                                                                <MenuItemComponent text={t('common.delete')} icon={<DeleteIcon fontSize="small" />}
                                                                    onClick={() => item.deleteDialogState = true} style={{ color: '#D14343' }} />
                                                            </MenuComponent>
                                                        }
                                                    </Cell>
                                                </TableRow>
                                                {open && (
                                                    <ExpandRow key={`${item.dto.id}_`} item={item}>
                                                        <OrganizationEmployeeDetails
                                                            store={item}
                                                        />
                                                    </ExpandRow >)
                                                }
                                            </Fragment>)
                                    })
                                    : <Cell colSpan={10}>
                                        <EmptyBox />
                                    </Cell>
                            }
                        </TableBody>
                    </Table>
                </Scrollbar>
            </Card>
            {!store.items.length && <Box
                sx={{
                    flexGrow: 1,
                    py: 3,
                }}
            >
                <Progress store={store} />
            </Box>
            }
            <PaginationLocal store={store} />
        </>
    );
});
