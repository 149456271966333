import {DoubleEnum, OrganizationField, PersonField, RequestField, RequestFieldTypeEnum} from "../../types";
import {t} from "i18next";
import {DefaultValueStore} from "../../../../../components/shared/DefaultValueStore";
import {getById} from "../../services";
import {FieldInfoDto} from "../../../../../services/dcr";
import {PersonStore} from "../specifications/entity/PersonStore";
import {OrganizationStore} from "../specifications/entity/OrganizationStore";
import {AzSpecification} from "../specifications/clients/AzSpecification";

export abstract class BaseFieldBuilder<S extends (PersonStore | OrganizationStore)> {
    constructor(store: S) {
        this.store = store
    }

    abstract buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void>;

    external: boolean = false;
    store: S;
    field?: RequestField;

    async build(element: FieldInfoDto): Promise<RequestField> {
        const field: RequestField = {
            name: t(`dcrPage.fields.${element.field}`),
            id: element.value,
            store: new DefaultValueStore<string>(element.value ? element.value : ""),
            isAdditional: element.isAdditional,
            field: <OrganizationField | PersonField>element.field,
            readonly: false,
            isHide: false
        }
        field.store.required = element.isRequired;

        this.external = element.requestFieldType === RequestFieldTypeEnum.External
        this.field = field;

        await this.buildSpecific(element, field)

        field.store.onChanged.push((e) => {
            if (this.store._store.errors.has(field.field)) {
                this.store._store.errors.delete(field.field)
            }
        })

        await field.store.validate()

        return field;
    }

    protected async getById<T, D>(params: {
        id: D | null,
        external: boolean,
        getInternal: (id: D) => Promise<T>,
        getExternal?: (id: D) => Promise<T[]>,
        error?: string
    }): Promise<T | null> {
        let res = null;
        try {
            res = await getById(params);
        } catch (e) {
            const error = params.error ? params.error : t("dcrPage.errors.mainError")
                .replace("name", this.field!.name)
                .replace("value", String(params.id!));
                if(!this.store._store.isExternalApiClient){
                    this.store._store.errors.set(this.field!.field, error)
                }
        }
        if ((res as any)?.['isInactive']) {
            res = null;
        }
        return res;
    }
}


export class DefaultBuilder<T extends (PersonStore | OrganizationStore)> extends BaseFieldBuilder<T> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
    }
}


export class DoubleBuilderBuilder<T extends (PersonStore | OrganizationStore)> extends BaseFieldBuilder<T> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store = new DefaultValueStore(element.value == DoubleEnum.Double);
        field.store.required = false;

        const checkboxHandler = async (isDouble: boolean) => {
            const originalId = this.store._store.fields.find(x => x.field == 'originalId')!
            originalId.isHide = !isDouble

            if (!isDouble)
                originalId.store.value = null;

            this.store._store.fields.forEach(x => x.store.validate());
            if (this.store._store.isAz) {
                (this.store._store.specification as AzSpecification).handleAzDoubleCheckBoxComment();
            }

        }

        field.store.onChanged.push((isDouble) => checkboxHandler(!!isDouble))
        field.store.getReadValue = (e) => t(!!e ? "common.yes" : "common.no")
        field.store.type = "checkbox"
    }
}
