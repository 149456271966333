import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

export interface Props {
    title?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    open: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
    hasTitle?: boolean;
    style?: React.CSSProperties;
}

export const ExpertDialog = ({ children, title, open, onClose, size, hasTitle = true,style }: Props) => {

    return (
        <Dialog
            style={{...style, border: 'unset'}}
            fullWidth
            maxWidth={size}
            onClose={onClose}
            open={open}
            scroll='body'
        >
            {
                hasTitle &&
                <DialogTitle
                    data-testid='dialog-title'
                    sx={{
                        margin: 0,
                        padding: theme => theme.spacing(2),
                    }}
                >
                    {
                        title &&
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    }
                    {onClose
                        ? (
                            <IconButton aria-label="close"
                                        sx={{
                                            position: 'absolute',
                                            marginTop: 0.5,
                                            right: theme => theme.spacing(1),
                                            top: theme => theme.spacing(1),
                                            color: theme => theme.palette.grey[500],
                                        }}
                                        onClick={onClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        )
                        : null}
                </DialogTitle>
            }

            <DialogContent
                data-testid="dialog-content"
                sx={{
                    margin: 0,
                }}
                dividers
            >
                {children}
            </DialogContent>
        </Dialog>
    );
};