import { Box } from "@mui/material";
import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { AuthorizePermission, CreateButtonsGroup, FormRow } from '../../../components';
import { DefaultFormModalStore } from '../../../components/shared/DefaultFormModalStore';
import { DefaultValueStore } from '../../../components/shared/DefaultValueStore';
import { DefaultValue } from '../../../components/shared/components/DefaultValue';
import { AllPermissions } from '../../../core/const';
import { StoreProps } from '../../../stores';

export const NotesCreate = observer(({ store }: StoreProps<DefaultFormModalStore<{
    notes: DefaultValueStore<string>
}>>) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ p: 2 }}>
            <FormRow style={{ height: "max-content", marginBottom: 8 }}>
                <DefaultValue
                    testid="NotesCreate-note"
                    style={{ width: '100%' }}
                    store={store.fields.notes}
                    title={t('common.notes')}
                    disabled={store.processing}
                    multiline
                    InputProps={{
                        sx: {
                            width: '100%',
                            "&.MuiInputBase-root": {
                                height: '100%'
                            }
                        }
                    }}
                />
            </FormRow>
            <AuthorizePermission permissions={AllPermissions.person.create}>
                <CreateButtonsGroup
                    onCancel={() => {
                        store.Open = false
                        store.reset()
                    }}
                    isCancelBtnDisabled={store.processing}
                    onSave={async () => await store.submit()}
                    isSaveBtnDisabled={store.processing || !store.valid || !store.fields.notes.valid}
                    testid="clientCreator" />
            </AuthorizePermission>
        </Box>
    );
});
