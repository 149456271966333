import {makeObservable, observable} from 'mobx';
import {
    EmailStatusDto,
    EmailTypeDto,
    GetPersonEmailsCountRequest,
    PersonDto,
    PersonEmailsApi,
    PersonEmailStatusesApi,
    PersonEmailTypesApi,
    PhoneStatusDto,
    PhoneTypeDto,
} from '../../../../../../services/management';
import i18n from "i18next";
import {PersonEmailItemStore} from './PersonEmailItemStore';
import {DefaultValueStore} from '../../../../../../components/shared/DefaultValueStore';
import {DefaultSelectStore} from '../../../../../../components/shared/DefaultSelectStore';
import {getApiConfiguration, getObject, setObject} from '../../../../../../core';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../../utils';
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {PersonItemStore} from "../../../PersonItemStore";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";

export class PersonEmailTableStore extends TableStore<PersonEmailItemStore> {
    _store: PersonItemStore
    _dto: PersonDto
    personId: string

    current: PersonEmailItemStore | undefined | null;
    readonly creator: DefaultFormModalStore<{
        emailAddress: DefaultValueStore<string>,
        type: DefaultSelectStore<EmailTypeDto>,
        status: DefaultSelectStore<EmailStatusDto>,
        description: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        emailAddress: new DefaultValueStore<string>("",
            (value) => new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(value ?? "") ? "" : i18n.t('errors.email')),
        type: new DefaultSelectStore<EmailTypeDto>(
            null, (x) => new PersonEmailTypesApi(getApiConfiguration()).getPersonEmailTypes(x),
            (value) => !value ? i18n.t('errors.empty') : ""),
        status: new DefaultSelectStore<EmailStatusDto>(
            null, (x) => new PersonEmailStatusesApi(getApiConfiguration()).getPersonEmailStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : ""),
        description: new DefaultValueStore<string>("", (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")
    }, (model) => this.create(model));

    statusFilter: DefaultMultiSelectStore<PhoneStatusDto> = new DefaultMultiSelectStore<PhoneStatusDto>([],
        (x) => new PersonEmailStatusesApi(getApiConfiguration()).getPersonEmailStatuses(x), (x) => this.updateState());
    typeFilter: DefaultMultiSelectStore<PhoneTypeDto> = new DefaultMultiSelectStore<PhoneTypeDto>([],
        (x) => new PersonEmailTypesApi(getApiConfiguration()).getPersonEmailTypes(x), (x) => this.updateState());
    description: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (x) => this.updateState());

    constructor(store: PersonItemStore, dto: PersonDto) {
        super();
        this._store = store
        this._dto = dto
        this.personId = this._dto.id


        const filters = getObject<TableLocalFilters<PersonEmailItemStore>>('persons-email-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        makeObservable(this, {
            personId: observable,
            current: observable,
        });
    }


    async request(): Promise<PagedItems<PersonEmailItemStore>> {
        const countFilters: GetPersonEmailsCountRequest = {
            personId: this._dto.id,
            emailStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            emailTypeIds: this.typeFilter.value?.length ? this.typeFilter.value.map(x => x.id) : undefined,
        }
        const api: PersonEmailsApi = new PersonEmailsApi(getApiConfiguration());
        const count = await api.getPersonEmailsCount(countFilters);

        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this.personId,
            emailStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            emailTypeIds: this.typeFilter.value?.length ? this.typeFilter.value.map(x => x.id) : undefined,
            description: this.description.value ?? ""
        };
        const result = await api.getPersonEmails(filters);

        const newList: PersonEmailItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new PersonEmailItemStore(this, item))
        }
        return new PagedItems<PersonEmailItemStore>(newList, count.count)
    };

    async create(model: any): Promise<void> {
        this.processing = true;
        const api: PersonEmailsApi = new PersonEmailsApi(getApiConfiguration());
        try {
            const result = await api.setPersonEmails({
                personId: this._dto.id,
                setPersonEmailDto: [
                    {
                        personId: this._dto.id,
                        emailAddress: this.creator.fields.emailAddress.value!,
                        emailStatusId: this.creator.fields.status.value?.id!,
                        emailTypeId: this.creator.fields.type.value?.id!,
                        description: this.creator.fields.description.value,
                    }
                ]
            });
            await this.pull();
            await this._store.updateState()
        } finally {
            this.processing = false;
        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<PersonEmailItemStore>>('persons-email-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
