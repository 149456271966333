import { makeAutoObservable } from 'mobx';
import { getApiConfiguration, IExpanded, IForm, IProcessing } from '../../../../../core';
import {OrganizationWebsiteDto, OrganizationWebsitesApi, WebsiteStatusDto, OrganizationWebsiteStatusesApi} from '../../../../../services/management';
import { DefaultSelectStore } from "../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import { OrganizationWebsiteTableStore } from './OrganizationWebsiteTableStore';


export class OrganizationWebsiteItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationWebsiteTableStore;
    dto: OrganizationWebsiteDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    status: DefaultSelectStore<WebsiteStatusDto>

    constructor(store: OrganizationWebsiteTableStore, dto: OrganizationWebsiteDto) {
        this._store = store;
        this.dto = dto;
        this.status = new DefaultSelectStore<WebsiteStatusDto>(
            dto?.websiteStatus, (x) => new OrganizationWebsiteStatusesApi(getApiConfiguration()).getOrganizationWebsiteStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : "");

        makeAutoObservable(this);
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: OrganizationWebsitesApi = new OrganizationWebsitesApi(getApiConfiguration());
        try {
            const result = await api.removeOrganizationWebsites({
                organizationId: this._store.organizationId,
                removeOrganizationWebsiteDto: [
                    {
                        websiteAddress: this.dto.website.address,
                        organizationId: this._store.organizationId,
                    }
                ]
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result[0].id);
            await this._store.pull();
        }
        catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.dto.websiteStatus.id === this.status.value?.id
    }

    get valid(): boolean {
        return this.status.valid;
    }

    validate() {
        this.status.validate();
    }

    update(dto: OrganizationWebsiteDto) {
        this.dto = dto;
        this.status.update(dto.websiteStatus);
        // this.type.update(dto.phoneType);
        // this.category.update(dto.phoneCategory);
    }

    reset(): void {
        this.status.reset();
        // this.category.reset();
        // this.type.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrganizationWebsitesApi = new OrganizationWebsitesApi(getApiConfiguration());

        try {
            let item = await api.updateOrganizationWebsite({
                id: this.dto.id,
                updateOrganizationWebsiteDto: {
                        websiteStatusId: this.status.value?.id!,
                    }
            })
            this.update(item);
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
