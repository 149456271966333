import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { AuthorizePermission, ParamsButtonGroup } from "../../../../components";
import { DefaultSelect } from "../../../../components/shared/components/DefaultSelect";
import { AllPermissions } from "../../../../core/const";
import { OrganizationEmailItemStore } from "../../../../stores/management/organizations/details/emails/OrganizationEmailItemStore";

interface Props {
    store: OrganizationEmailItemStore;
    style?: React.CSSProperties;
}

export const OrganizationEmailDetails = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    return (
        <Box style={{ ...style, padding: '16px 0 8px 16px' }}>
            <Box sx={{ display: "flex", mb: 1 }}>
                <DefaultSelect store={store.status}
                    title={t('common.status')}
                    style={{ width: '30%', marginRight: 16 }}
                    testid="organizationEmailDetails-status"
                    hasLabel
                />
                <DefaultSelect store={store.type}
                    title={t('common.type')}
                    style={{ width: '30%', marginRight: 16 }}
                    testid="organizationEmailDetails-type"
                    hasLabel
                />
                <Box sx={{ ml: 'auto', pr: 4 }}>
                    <AuthorizePermission permissions={AllPermissions.organization.emails.update}>
                        <ParamsButtonGroup
                            testid="organizationEmailDetails"
                            onCancel={() => store.reset()}
                            onSave={async () => await store.submit()}
                            isCancelBtnDisabled={store.processing || store.equals}
                            isSaveBtnDisabled={store.processing || store.equals || !store.valid}
                        />
                    </AuthorizePermission>
                </Box>
            </Box>
        </Box>
    );
});
