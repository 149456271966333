import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { AuthorizePermission, ParamsButtonGroup } from "../../../../components";
import { DefaultSelect } from "../../../../components/shared/components/DefaultSelect";
import { DefaultValue } from "../../../../components/shared/components/DefaultValue";
import { AllPermissions } from "../../../../core/const";
import { TypeNameDto } from "../../../../services/management";
import { OrganizationNameItemStore } from "../../../../stores/management/organizations/details/names/OrganizationNameItemStore";

interface Props {
    store: OrganizationNameItemStore;
    style?: React.CSSProperties;
}

export const OrganizationNameDetails = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    return (
        <Box style={style} sx={{ pl: 2, pt: 2, pr: 0 }}>
            <Box sx={{ display: "flex", mb: 2 }}>
                <DefaultValue store={store.name}
                    title={t('common.name')}
                    style={{ width: '50%', marginRight: 10 }}
                    testid="organizationNameDetails-name"
                />
                <DefaultSelect store={store.type}
                    title={t('common.type')}
                    style={{ width: '50%' }}
                    testid="organizationNameDetails-type"
                    nameExp={(x: TypeNameDto) => store._store.listNames.filter(x => x !== store.dto?.typeName?.nameRus).includes(x.nameRus) ? '' : x.nameRus}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                }}
            >
                <AuthorizePermission permissions={AllPermissions.organization.names.update}>
                    <ParamsButtonGroup
                        testid="organizationNameDetails"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.submit()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals || !store.valid}
                    />
                </AuthorizePermission>
            </Box>
        </Box>
    );
});
