import {makeAutoObservable} from 'mobx';
import {getObject, IExpanded, IHasTabs, IProcessing, setObject, toDateTimeString,} from '../../../core';
import {
    AddressUniqueDto,
    OrganizationDto,
    OrganizationStatusDto,
    OrgNameDto,
    TypeLocalityDto,
    TypeOrgDto,
} from '../../../services/management';
import {OrganizationTableStore} from './OrganizationTableStore';
import {OrganizationNameTableStore} from './details/names/OrganizationNameTableStore';
import {OrganizationPropertiesStore} from './details/OrganizationPropertiesStore';
import {OrganizationAddressesStore} from './details/OrganizationAddressStore';
import {getOrgName} from '../../utils';
import {OrganizationEmployeesTableStore} from './details/employees/OrganizationEmployeesTableStore';
import {OthersTabsStore} from './details/others/OthersTabsStore';
import {ParentChildTabsStore} from './details/parentChild/ParentChildTabsStore';
import {DoubleTableStore} from './doubles/DoubleTableStore';
import {NotesTableStore} from './details/notes/NotesTableStore';

interface OrganizationViewModelLocalFilters {
    tabIndex: number;
    expand: boolean;
}

export type IAddress = {
    parent?: object & { id: string };
    parentId?: string | null;
    nameRus: string;
    typeLocality: TypeLocalityDto;
};

export class OrganizationItemStore implements IExpanded, IHasTabs, IProcessing {
    readonly _store: OrganizationTableStore;
    _dto: OrganizationDto;
    id: string;
    externalId: string;
    itn: string;
    private _tabIndex = 0;
    private _expand: boolean = false;
    private _date?: Date | null;
    deleteDialogState: boolean = false;
    orgName: string = '';
    orgStatus: OrganizationStatusDto;
    typeOrg: TypeOrgDto;
    address: AddressUniqueDto;
    countNotes: number = 0;

    orgNamesStore: OrganizationNameTableStore;
    propertiesStore: OrganizationPropertiesStore;
    addressesStore: OrganizationAddressesStore;
    parentChildStore: ParentChildTabsStore;
    employeesStore: OrganizationEmployeesTableStore;
    othersStore: OthersTabsStore;
    doubleStore: DoubleTableStore;
    notesTable: NotesTableStore;

    constructor(store: OrganizationTableStore, dto: OrganizationDto) {
        this._store = store;
        this._dto = dto;

        this.id = dto.id;
        this.externalId = dto.externalId ?? '';
        this.itn = dto?.itn?.itn ?? '';
        this.orgName = getOrgName(dto);
        this.address = dto.addressUnique;
        this.orgStatus = dto.status;
        this.typeOrg = dto.typeOrg;
        this._date = dto.modifiedAt;

        this.orgNamesStore = new OrganizationNameTableStore(this, dto);
        this.propertiesStore = new OrganizationPropertiesStore(this, dto);
        this.addressesStore = new OrganizationAddressesStore(this, dto.addressUnique);
        this.parentChildStore = new ParentChildTabsStore(this, dto);
        this.employeesStore = new OrganizationEmployeesTableStore(this, dto);
        this.othersStore = new OthersTabsStore(this, dto);
        this.doubleStore = new DoubleTableStore(this);
        this.notesTable = new NotesTableStore(this);

        const filters = getObject<OrganizationViewModelLocalFilters>(
            `organizations-item-${this.id}`
        );
        if (filters) {
            this._tabIndex = filters.tabIndex;
            this._expand = filters.expand;
        }
        if (this.expand) {
            this.updateState();
            this.propertiesStore.pullClinicCategory();
        }

        makeAutoObservable(this);
    }

    get expand() {
        return this._expand;
    }

    set expand(value: boolean) {
        this._expand = value;
        this.saveFilters();
        if (this.expand) {
            this.updateState();
            // this.loadDobles();
            this.propertiesStore.pullClinicCategory();
        }
    }

    get processing() {
        return (
            this.propertiesStore.processing ||
            this.addressesStore.processing ||
            this.parentChildStore.ParentStore.processing
        );
    }

    get tabIndex() {
        return this._tabIndex;
    }

    set tabIndex(value: number) {
        this._tabIndex = value;
        // if (value == 1) {
        //     this.orgNamesStore.clear()
        // }
        this.saveFilters();
        this.updateState();
    }

    get modifiedAt() {
        if (this._date) {
            return toDateTimeString(this._date);
        }
        return '';
    }

    update(dto: OrganizationDto) {
        this._dto = dto;
        this.id = dto.id;
        this.externalId = dto?.externalId ?? '';
        this.itn = dto.itn?.itn;
        this.orgStatus = dto.status;
        this.typeOrg = dto.typeOrg;
        this._date = dto.modifiedAt;

        this.orgName = getOrgName(dto);
        this.address = dto.addressUnique;

        this.addressesStore.update(this._dto.addressUnique)

        if (this.expand) this.updateState();
    }

    loadDobles() {
        // this.propertiesStore.pullAntiDoble();
        this.propertiesStore.pullDoble();
    }

    loadClinicCategory() {
        this.propertiesStore.pullClinicCategory();
    }

    updateState() {
        if (this._tabIndex === 0) {
            this.loadDobles();
            this.propertiesStore.update(this._dto);
        } else if (this._tabIndex === 1) {
            this.doubleStore.pull().then();
        } else if (this._tabIndex === 2) {
            this.orgNamesStore.pull().then();
        } else if (this._tabIndex === 3) {
            this.addressesStore.pull().then();
        } else if (this._tabIndex === 4) {
            this.parentChildStore.update(this._dto);
            this.parentChildStore.handleTabChange().then();
        } else if (this._tabIndex === 5) {
            this.othersStore.updateState();
        } else if (this._tabIndex === 6) {
            this.employeesStore.pull().then();
        } else if (this._tabIndex === 7) {
            this.notesTable.pull().then();
        }
        this?.loadClinicCategory();
        this?.notesTable.countNotes(this.id).then((x) => (this.countNotes = x));
    }

    saveFilters() {
        setObject<OrganizationViewModelLocalFilters>(
            `organizations-item-${this.id}`,
            {
                tabIndex: this._tabIndex,
                expand: this._expand,
            }
        );
    }

    updateNamesByDelete(id: string) {
        this._dto.names = this._dto.names.filter((t) => t.id !== id);
        this.orgName = getOrgName(this._dto);
    }

    updateNamesByAdd(dto: OrgNameDto) {
        this._dto.names.push(dto);
        this.orgName = dto.name;
    }
}
