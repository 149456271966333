import {makeObservable, observable} from 'mobx';
import {getApiConfiguration, getObject, setObject} from '../../../../../../core';
import {
    GetPersonWebsitesCountRequest,
    PersonDto,
    PersonWebsitesApi,
    PersonWebsiteStatusesApi,
    WebsiteStatusDto
} from '../../../../../../services/management';
import {PagedItems, TableLocalFilters, TableStore} from '../../../../../utils';
import {DefaultSelectStore} from "../../../../../../components/shared/DefaultSelectStore";
import {DefaultValueStore} from "../../../../../../components/shared/DefaultValueStore";
import i18n from "i18next";
import {PersonWebsiteItemStore} from './PersonWebsiteItemStore';
import {PersonItemStore} from "../../../PersonItemStore";
import {DefaultFormModalStore} from "../../../../../../components/shared/DefaultFormModalStore";
import {DefaultMultiSelectStore} from "../../../../../../components/shared/DefaultMultiSelectStore";

export class PersonWebsiteTableStore extends TableStore<PersonWebsiteItemStore> {
    _persStore: PersonItemStore
    _dto: PersonDto
    personId: string

    current: PersonWebsiteItemStore | undefined | null;

    readonly creator: DefaultFormModalStore<{
        websiteAddress: DefaultValueStore<string>,
        websiteStatus: DefaultSelectStore<WebsiteStatusDto>,
        description: DefaultValueStore<string>
    }> = new DefaultFormModalStore({
        websiteAddress: new DefaultValueStore<string>("",
            (value) => new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/).test(value ?? "") ? "" : i18n.t('errors.website')),
        websiteStatus: new DefaultSelectStore<WebsiteStatusDto>(
            null, (x) => new PersonWebsiteStatusesApi(getApiConfiguration()).getPersonWebsiteStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : ""),
        description: new DefaultValueStore<string>("", (value) =>
            value?.length! > 180 ? i18n.t('errors.lengthMax').replace("lengthMax", "180") : "")
    }, (model) => this.create(model));


    statusFilter: DefaultMultiSelectStore<WebsiteStatusDto> = new DefaultMultiSelectStore<WebsiteStatusDto>([],
        (x) => new PersonWebsiteStatusesApi(getApiConfiguration()).getPersonWebsiteStatuses(x), (x) => this.updateState());
    description: DefaultValueStore<string> = new DefaultValueStore<string>("", null, (x) => this.updateState());

    constructor(persStore: PersonItemStore, dto: PersonDto) {
        super();
        this._persStore = persStore
        this._dto = dto
        this.personId = this._dto.id

        const filters = getObject<TableLocalFilters<PersonWebsiteItemStore>>('persons-website-store-id-' + this._dto.id);

        if (filters) {
            this._page = filters.page;
            this._size = filters.size;
            this.search.setValueWithoutEffects(filters.search);;
            this._order = filters.order;
            this._orderBy = filters.orderBy;
        }

        this.statusFilter.onChanged.push(async (e) => await this.updateState())

        makeObservable(this, {
            personId: observable,
            current: observable,
        });
    }

    async request(): Promise<PagedItems<PersonWebsiteItemStore>> {
        const countFilters: GetPersonWebsitesCountRequest = {
            personId: this._dto.id,
            websiteStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
        }
        const api: PersonWebsitesApi = new PersonWebsitesApi(getApiConfiguration());
        const count = await api.getPersonWebsitesCount(countFilters);
        const filters = {
            page: this.page + 1,
            size: count.count,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
            personId: this.personId,
            websiteStatusIds: this.statusFilter.value?.length ? this.statusFilter.value.map(x => x.id) : undefined,
            description: this.description.value ?? ""
        };
        const result = await api.getPersonWebsites(filters);
        const newList: PersonWebsiteItemStore[] = [];
        for (const item of result) {
            const existsItem = this.items.find(t => t.dto.id === item.id);
            if (existsItem) {
                existsItem.update(item);
                newList.push(existsItem);
                continue;
            }
            newList.push(new PersonWebsiteItemStore(this, item))
        }
        return new PagedItems<PersonWebsiteItemStore>(newList, count.count)
    };

    async create(model: any): Promise<void> {
        this.processing = true;
        const api: PersonWebsitesApi = new PersonWebsitesApi(getApiConfiguration());
        try {
            await api.setPersonWebsites({
                personId: this._dto.id,
                setPersonWebsiteDto: [
                    {
                        personId: this._dto.id,
                        websiteAddress: this.creator.fields.websiteAddress.value!,
                        websiteStatusId: this.creator.fields.websiteStatus.value?.id!,
                        description: this.creator.fields.description.value,
                    }
                ]
            });
            await this.pull();
            await this._persStore.updateState()
        } finally {
            this.processing = false;
        }
    };

    onFiltersSave() {
        setObject<TableLocalFilters<PersonWebsiteItemStore>>('persons-website-store-id-' + this._dto.id, {
            page: this.page,
            size: this.size,
            search: this.search.value ?? '',
            order: this.order,
            orderBy: this.orderBy,
        });
    }
}
