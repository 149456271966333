import {Box, LinearProgress} from '@mui/material';
import {observer} from 'mobx-react';
import {ILoad, IProcessing} from '../../core';

type Props = {
    store: Partial<Pick<ILoad, 'loading'> & Pick<IProcessing, 'processing'>>;
};

export const Progress = observer(({ store }: Props) => {
    return (
        (store.loading || store.processing)
        ?  <Box
                sx={{
                    flexGrow: 1,
                    py: 3,
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'background.default',
                    zIndex: 999
                }}
            ><LinearProgress style={{ width: '100%' }} /></Box>
        : null
    );
});

