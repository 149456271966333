import { observer } from "mobx-react";
import { CreateButtonsGroup, FormRoot, FormRow } from "../../../../components";
import { DefaultSelect } from "../../../../components/shared/components/DefaultSelect";
import React from "react";
import { DefaultSelectStore } from "../../../../components/shared/DefaultSelectStore";
import { DefaultValueStore } from "../../../../components/shared/DefaultValueStore";
import {
    WebsiteStatusDto,
} from "../../../../services/management";
import { useTranslation } from "react-i18next";
import { DefaultValue } from "../../../../components/shared/components/DefaultValue";
import {DefaultFormModalStore} from "../../../../components/shared/DefaultFormModalStore";

interface Props {
    store: DefaultFormModalStore<{
        websiteAddress: DefaultValueStore<string>,
        websiteStatus: DefaultSelectStore<WebsiteStatusDto>,
    }>;
}

export const OrganizationWebsiteCreator = observer(({ store }: Props) => {
    const { t } = useTranslation();

    return (
        <FormRoot sx={{ p: 2 }}>
            <FormRow>
                <DefaultValue
                    testid="organizationWebsiteCreator-website"
                    style={{ width: '100%' }}
                    store={store.fields.websiteAddress}
                    title={t('common.orgWebsite')}
                    disabled={store.processing}
                />
            </FormRow>
            <FormRow>
                <DefaultSelect store={store.fields.websiteStatus}
                    title={t('common.status')}
                    testid="organizationWebsiteCreator-status"
                />
            </FormRow>
            <CreateButtonsGroup
                onCancel={() => store.Open = false}
                onSave={async () => await store.submit()}
                isCancelBtnDisabled={store.processing}
                isSaveBtnDisabled={store.processing || !store.valid}
                testid="organizationWebsiteCreator"
            />
        </FormRoot>
    );
});
