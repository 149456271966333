import { makeAutoObservable } from 'mobx';
import { getApiConfiguration, IExpanded, IForm, IProcessing } from '../../../../../core';
import {
    EmailStatusDto,
    OrganizationEmailStatusesApi,
    EmailTypeDto,
    OrganizationEmailTypesApi,
    OrganizationEmailDto,
    OrganizationEmailsApi,
} from '../../../../../services/management';
import { DefaultSelectStore } from "../../../../../components/shared/DefaultSelectStore";
import i18n from "i18next";
import { OrganizationEmailTableStore } from './OrganizationEmailTableStore';


export class OrganizationEmailItemStore implements IProcessing, IExpanded, IForm {
    readonly _store: OrganizationEmailTableStore;
    dto: OrganizationEmailDto;
    processing: boolean = false;
    deleteDialogState: boolean = false;
    expand: boolean = false;
    type: DefaultSelectStore<EmailTypeDto>;
    status: DefaultSelectStore<EmailStatusDto>;

    constructor(store: OrganizationEmailTableStore, dto: OrganizationEmailDto) {
        this._store = store;
        this.dto = dto;
        this.type = new DefaultSelectStore<EmailTypeDto>(
            dto.emailType, (x) => new OrganizationEmailTypesApi(getApiConfiguration()).getOrganizationEmailTypes(x),
            (value) => !value ? i18n.t('errors.empty') : "");
        this.status = new DefaultSelectStore<EmailStatusDto>(
            dto.emailStatus, (x) => new OrganizationEmailStatusesApi(getApiConfiguration()).getOrganizationEmailStatuses(x),
            (value) => !value ? i18n.t('errors.empty') : "");

        makeAutoObservable(this);
    }

    async delete(): Promise<void> {
        this.processing = true;
        const api: OrganizationEmailsApi = new OrganizationEmailsApi(getApiConfiguration());
        try {
            const result = await api.removeOrganizationEmails({
                organizationId: this._store.organizationId,
                removeOrganizationEmailDto: [
                    {
                        emailAddress: this.dto.email.address,
                        organizationId: this._store.organizationId
                    }
                ]
            });
            this._store.items = this._store.items.filter(t => t.dto.id !== result[0].id);
            await this._store.pull();
        }
        catch (e) {

        }
        this._store.current = null;
        this.processing = false;
    };

    get equals(): boolean {
        return this.dto.emailStatus.id === this.status.value?.id
            && this.dto.emailType.id === this.type.value?.id
    }

    get valid(): boolean {
        return this.status.valid && this.type.valid;
    }

    validate() {
        this.status.validate();
        this.type.validate();
    }

    update(dto: OrganizationEmailDto) {
        this.dto = dto;
        this.status.update(dto.emailStatus);
        this.type.update(dto.emailType);
    }

    reset(): void {
        this.status.reset();
        this.type.reset();
        this.validate();
    }

    async submit() {
        this.validate()
        if (!this.valid)
            return
        this.processing = true;
        const api: OrganizationEmailsApi = new OrganizationEmailsApi(getApiConfiguration());

        try {
            let item = await api.updateOrganizationEmail({
                id: this.dto.id,
                updateOrganizationEmailDto: {
                        emailStatusId: this.status.value?.id!,
                        emailTypeId: this.type.value?.id!,
                    }
            })
            this.update(item);
            this._store.updateState();
        } finally {
            this.processing = false;
        }
    }
}
