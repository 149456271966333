import { Box, Card, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell,
    EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search
} from '../../../../components';
import { NavLink } from "../../../../components/NavLink";
import { toDateTimeString } from "../../../../core";
import { AllPermissions } from "../../../../core/const";
import { StoreProps } from "../../../../stores";
import { UserTableStore } from "../../../../stores/management/users/tabs/Users/UserTableStore";
import { UserCreator } from "./UserCreator";
import { UserDetails } from "./UserDetails";

export const UserTableTab = observer(({ store }: StoreProps<UserTableStore>) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
                backgroundColor: 'background.default',
                height: '100%'
            }}
            >
                <ExpertDialog
                    title={t('create.user')}
                    open={store.creator.Open}
                    onClose={() => store.creator.Open = false}
                >
                    <UserCreator store={store.creator} />
                </ExpertDialog>

                <Box sx={{ mb: 2 }}>
                    <Box
                        sx={{
                            mt: 3,
                            mb: 2
                        }}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Search
                            value={store.search.value}
                            onChange={t => store.search.value = t}
                            style={{ width: '87%', marginRight: 8 }}
                            testid="users"
                        />
                        <AuthorizePermission permissions={AllPermissions.user.create}>
                            <AddButton onClick={() => store.creator.Open = true} />
                        </AuthorizePermission>
                    </Box>
                    <Card sx={{ mb: 8, overflow: 'unset' }}>
                        <Table sx={{ minWidth: 800 }}>
                            <TableHead style={{ position: 'sticky', top: 64, zIndex: 999 }}>
                                <TableRow>
                                    <HCell width='5%' testid="users-expand" />
                                    <HCell orderBy='userName' store={store} testid="users-login">{t('table.login')}</HCell>
                                    <HCell orderBy='userName' store={store} testid="users-name">{t('table.uName')}</HCell>
                                    <HCell orderBy='createdAt' width='15%' testid="users-createdAt" store={store}>{t('table.createdDate')}</HCell>
                                    <HCell orderBy='modifiedAt' width='15%' store={store} testid="users-modifiedAt">{t('table.date')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {store.items.length > 0 && store.items.map((user) => {
                                    return (
                                        <Fragment key={user.dto.id}>
                                            <TableRow
                                                hover
                                                key={user.dto.id}
                                            >
                                                <ExpandButton item={user}
                                                    isOpen={user.expand} testid="users" />

                                                <Cell testid="users-login">
                                                    <NavLink to={`${user.dto.id}`}>
                                                        <Typography variant="subtitle2" sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}>
                                                            {user.dto.username}
                                                        </Typography>
                                                    </NavLink>
                                                </Cell>
                                                <Cell testid="users-name">
                                                    <NavLink to={`${user.dto.id}`}>
                                                        <Typography variant="subtitle2" sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                        >
                                                            {user.dto.name}
                                                        </Typography>
                                                    </NavLink>
                                                </Cell>
                                                <Cell testid="users-createdAt">
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {toDateTimeString(user.dto.createdAt)}
                                                    </Typography>
                                                </Cell>
                                                <Cell style={{ paddingRight: 50 }} testid="users-modifiedAt">
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {toDateTimeString(user.dto.modifiedAt)}
                                                    </Typography>
                                                </Cell>
                                            </TableRow>
                                            {user.expand && (
                                                <ExpandRow key={`${user.dto.id}_`} item={user}>
                                                    <UserDetails
                                                        store={user}
                                                        style={{
                                                            margin: '0 40px'
                                                        }}
                                                    />
                                                </ExpandRow>)}
                                        </Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!store.items.length && <EmptyBox />}
                        {store.items.length > 0 && <Pagination store={store} />}

                        <Progress store={store} />

                    </Card>
                </Box>
            </Box>
        </Box>
    );
});
