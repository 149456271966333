import {getApiConfiguration} from "../../../../../../core";
import {FullAddressFields, getById} from "../../../services";
import {RequestFieldTypeEnum} from "../../../types";
import {
    LocalitiesApi,
    LocalityDto,
    TypeLocalitiesApi,
    TypeLocalityDto,
    ZipDto,
    ZipsApi
} from "../../../../../../services/management";
import {OrganizationStore} from "../../specifications/entity/OrganizationStore";
import {createElement, FunctionComponentElement} from "react";
import {computed, makeObservable, observable} from "mobx";
import {AddressComponents} from "../../../../../../pages/dcr/requests/fieldsComponents/AddressComponents";
import {ChangeRequestField} from "../../../../../../services/dcr";
import {AddressDto, DefaultAddressesStore} from "../../../../../../shared/address/DefaultAddressStore";
import {CustomFieldStore} from "./CustomFieldStore";
import {t} from "i18next";
import {JurisdictionLocalSetting} from "../../../../../authorization/AuthorizationStore";

export class AddressStore extends CustomFieldStore<OrganizationStore> {

    private crmFullAddress: string;

    reset(): void {
        this.addressStore.reset();
    }

    validate(): void {
        this.addressStore.validate();
    }

    get valid(): boolean {
        return this.addressStore.valid
    }

    constructor(store: OrganizationStore) {
        super(store);
        this.Component = createElement(AddressComponents, {store: this}, null)
        this.addressStore = new DefaultAddressesStore(undefined, this);
        this.crmFullAddress = store._store.dto?.additionalFields['fullAddress'];
        makeObservable(this, {
            firstValues: observable,
            fullAddress: computed,
        })
        this.validate();
    }

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this.store._store._store._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    update(dto: { additionalFields: { [p: string]: string | null | undefined } | null | undefined; fields: ChangeRequestField[] | null | undefined } | null) {
        this.initFullAddress();
        this.initAddress().then(x => {
            this.addressStore.update(x)
            this.validate();
        })
    }

    Component?: FunctionComponentElement<any> | undefined;
    firstValues: { [key in string]: string; } = {};
    addressStore: DefaultAddressesStore;

    get readonly(): boolean {
        return !this.store._store.edit;
    }

    async pull(): Promise<void> {
        await this.initFullAddress();
        this.addressStore.update(await this.initAddress())
    }

    async initAddress(): Promise<AddressDto> {
        const address: AddressDto = {...this.store.addressDto} as AddressDto;

        if (this.store._store._store.isCompletedStatus) {
            return address;
        }

        const fields = FullAddressFields.filter(x => this.store._store.requestFields.map(q => q.field!).indexOf(x) > -1)
            .map(x => this.store._store.requestFields.find(q => q.field == x)!)

        for (const item of fields) {
            const rr = {value: item.value ?? "", type: item.requestFieldType!};
            switch (item.field) {
                case "postcode": {
                    const res = await this.getPostCode(rr);
                    address.zip = res ?? this.store.addressDto.zip;
                    if (item.value)
                        this.addressStore.clearValue = false;
                    break
                }
                case "building": {
                    address.house = !!item.value ? item.value : this.store.addressDto.house
                    if (!!item.value)
                        this.addressStore.clearValue = false;
                    break
                }
                case "building2": {
                    address.building = !!item.value ? item.value : this.store.addressDto.building
                    if (!!item.value)
                        this.addressStore.clearValue = false;
                    break
                }
                case "gps_lo": {
                    address.longtitude = !!item.value ? item.value : this.store.addressDto.longtitude
                    if (!!address.longtitude) {
                        this.addressStore.clearValue = false;
                    }
                    break
                }
                case "gps_la": {
                    address.latitude = !!item.value ? item.value : this.store.addressDto.latitude
                    if (!!address.longtitude) {
                        this.addressStore.clearValue = false;
                    }
                    break
                }
            }
        }
        this.addressStore.isCustomCoordinates.value = true;

        return address;
    }

    async getLocality(field: { type: RequestFieldTypeEnum, value: string }): Promise<LocalityDto | null> {
        const api = new LocalitiesApi(getApiConfiguration());
        return await getById({
            id: field.value,
            external: field.type == RequestFieldTypeEnum.External,
            getInternal: (x) => api.getLocalityById({id: x!}),
            getExternal: (x) => api.getLocalities({searchByExternalId: x}),
        });
    }

    async getStreetType(field: { type: RequestFieldTypeEnum, value: string }): Promise<TypeLocalityDto | null> {
        const api = new TypeLocalitiesApi(getApiConfiguration());
        return await getById({
            id: field.value,
            external: field.type == RequestFieldTypeEnum.External,
            getInternal: (x) => api.getTypeLocalityById({id: x!}),
            getExternal: (x) => api.getTypeLocalities({searchByExternalId: x}),
        })
    }

    async getPostCode(field: { type: RequestFieldTypeEnum, value: string }): Promise<ZipDto | null> {
        if (!field.value)
            return null;
        const api = new ZipsApi(getApiConfiguration());
        try {
            return await api.getZipByName({name: field.value!})
        } catch (e) {
            return null;
        }
    }

    async initFullAddress(): Promise<void> {
        if (!this.crmFullAddress) {
            const fields = FullAddressFields.filter(x => this.store._store.firstRequestFields.map(q => q.field).indexOf(x) > -1)
                .map(x => this.store._store.firstRequestFields.find(q => q.field == x)!)

            for (const item of fields) {
                const rr = {value: item.value ?? "", type: item.type!};

                switch (item.field) {
                    case "region": {
                        const res = await this.getLocality(rr);
                        this.firstValues[item.field!] = res?.nameRus ?? "";
                        break
                    }
                    case "city": {
                        const res = await this.getLocality(rr);
                        this.firstValues[item.field!] = res?.nameRus ?? "";
                        break
                    }
                    case 'streettype': {
                        const res = await this.getStreetType(rr);
                        this.firstValues[item.field!] = res?.nameRus ?? "";
                        break;
                    }
                    default: {
                        this.firstValues[item.field!] = item.value ?? "";
                    }
                }
            }
        }
    }

    get fullAddress(): any {
        if (this.crmFullAddress) {
            return this.store._store.requestFields.find(x => x.field == "fullAddress")?.value;
        } else {
            return Object.keys(this.firstValues).map((x, i) => {
                const label = t(`dcrPage.fields.${x}`)
                const value = this.firstValues[x]
                const isLast = i + 1 == Object.keys(this.firstValues).length;
                return `${label}: ${value}${!isLast ? ', ' : ''}`
            });
        }
    }

    get value(): { additionalFields: { [p: string]: string | null | undefined } | null | undefined; fields: ChangeRequestField[] | null | undefined } | null {
        if (!this.valid)
            return null;

        return {
            additionalFields: {
                gps_la: this.addressStore.latitude.value,
                gps_lo: this.addressStore.longtitude.value,
                building: this.addressStore.house.value,
                district: this.addressStore.district.value?.id,
                okrug: this.addressStore.okrug.value?.id,
                isCustomCoordinates: this.addressStore.isCustomCoordinates.value ? '1' : '0'
            },
            fields: [
                {
                    value: this.addressStore.latitude.value,
                    field: 'gps_la',
                    type: 2
                },
                {
                    value: this.addressStore.longtitude.value,
                    field: 'gps_lo',
                    type: 2
                },
                {
                    value: this.addressStore.locality.currentLocality?.id,
                    field: 'street',
                    type: 1
                },
                {
                    value: this.addressStore.house.value,
                    field: 'building',
                    type: 2
                },
                {
                    value: this.addressStore.building.value,
                    field: 'building2',
                    type: 2
                },
                {
                    value: this.addressStore.locality.currentLocality?.cityId,
                    field: 'city',
                    type: 1
                },
                {
                    value: this.addressStore.zip.value,
                    field: 'postcode',
                    type: 1
                }
            ]
        };
    }
}
