import {Box} from "@mui/material";
import {observer} from "mobx-react";
import React from "react";
import {useTranslation} from "react-i18next";
import {AuthorizePermission, ParamsButtonGroup} from "../../../../components";
import {DefaultSelect} from "../../../../components/shared/components/DefaultSelect";
import {AllPermissions} from "../../../../core/const";
import {PersonPhoneItemStore} from "../../../../stores/management/persons/tabs/others/phones/PersonPhoneItemStore";
import {DefaultValue} from "../../../../components/shared/components/DefaultValue";

interface Props {
    store: PersonPhoneItemStore;
    style?: React.CSSProperties;
}

export const PersonPhoneDetails = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    return (
        <Box style={{ ...style, padding: '16px 0 8px 16px' }}>
            <Box sx={{ display: "flex", mb: 1 }}>
                <DefaultValue
                    style={{width: '50%', marginRight: 16}}
                    store={store.phone}
                    disabled
                    title={t('common.phone')}
                />
                <DefaultSelect store={store.status}
                    title={t('common.status')}
                               style={{width: '50%'}}
                    testid="personPhoneDetails-status"
                    hasLabel
                />

            </Box>
            <Box sx={{display: "flex", mb: 1}}>
                <DefaultSelect store={store.type}
                               title={t('common.type')}
                               style={{width: '33%', marginRight: 16}}
                               testid="personPhoneDetails-type"
                               hasLabel
                />
                <DefaultSelect store={store.category}
                               title={t('common.category')}
                               style={{width: '33%', marginRight: 16}}
                               testid="personPhoneDetails-category"
                               hasLabel
                />
                <DefaultValue store={store.description}
                              title={t('common.note')}
                              style={{width: '33%'}}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 2
                }}
            >
                <AuthorizePermission permissions={AllPermissions.person.phones.update}>
                    <ParamsButtonGroup
                        testid="personPhoneDetails"
                        onCancel={() => store.reset()}
                        onSave={async () => await store.submit()}
                        isCancelBtnDisabled={store.processing || store.equals}
                        isSaveBtnDisabled={store.processing || store.equals || !store.valid}
                    />
                </AuthorizePermission>
            </Box>
        </Box>
    );
});
