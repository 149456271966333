import { Box, Stack } from "@mui/material";
import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";
import { AuthorizePermission, CreateButtonsGroup } from '../../../../components';
import { DefaultFormModalStore } from "../../../../components/shared/DefaultFormModalStore";
import { DefaultValueStore } from "../../../../components/shared/DefaultValueStore";
import { DefaultValue } from "../../../../components/shared/components/DefaultValue";
import { AllPermissions } from '../../../../core/const';
import { StoreProps } from "../../../../stores";


export const UserCreator = observer(({ store }: StoreProps<DefaultFormModalStore<{
    name: DefaultValueStore<string>,
    userName: DefaultValueStore<string>,
    password: DefaultValueStore<string>,
    rePassword: DefaultValueStore<string>
}>>) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ px: 2 }}>
            <Stack
                component="ul"
                direction="column"
                spacing={2}
                sx={{
                    listStyle: 'none',
                    p: 0,
                    mt: 'unset'
                }}
            >
                <DefaultValue
                    testid="createUserForm-login-input"
                    store={store.fields.userName}
                    disabled={store.processing}
                    title={t('usersPage.login')} />

                <DefaultValue
                    testid="createUserForm-username-input"
                    store={store.fields.name}
                    disabled={store.processing}
                    title={t('usersPage.username')} />
                <DefaultValue
                    testid="createUserForm-password-input"
                    store={store.fields.password}
                    disabled={store.processing}
                    title={t('usersPage.password')} />
                <DefaultValue
                    testid="createUserForm-passwordConfirm-input"
                    store={store.fields.rePassword}
                    disabled={store.processing}
                    title={t('usersPage.confirmPass')} />
            </Stack>
            <AuthorizePermission permissions={AllPermissions.user.create}>
                <CreateButtonsGroup onCancel={() => store.Open = false}
                    isCancelBtnDisabled={store.processing}
                    onSave={async () => await store.submit()}
                    isSaveBtnDisabled={store.processing || !store.valid}
                    testid="createUserForm" />

            </AuthorizePermission>
        </Box>
    );
});
