import {BaseFieldBuilder} from "./BaseFieldBuilder";
import {OrganizationStore} from "../specifications/entity/OrganizationStore";
import {FieldInfoDto} from "../../../../../services/dcr";
import {DCRStatusEnum, RequestField} from "../../types";
import {
    ClinicCategoriesApi,
    OrganizationDto,
    OrganizationPhoneCategoriesApi,
    OrganizationPhoneStatusesApi,
    OrganizationPhoneTypesApi,
    OrganizationsApi,
    OrganizationStatusDto,
    OrganizationStatusesApi,
    TypeOrgsApi
} from "../../../../../services/management";
import {getApiConfiguration} from "../../../../../core";
import i18n, {t} from "i18next";
import {DefaultSelectStore} from "../../../../../components/shared/DefaultSelectStore";
import {OrganizationStatuses} from "../../../../../core/const";
import {getOrgName} from "../../../../utils";
import {AzSpecification} from "../specifications/clients/AzSpecification";
import {JurisdictionData, JurisdictionLocalSetting} from "../../../../authorization/AuthorizationStore";

export class CompanyTypeBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new TypeOrgsApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: this.external,
            getInternal: (x) => api.getTypeOrgById({id: x!}),
            getExternal: (x) => api.getTypeOrgs({searchByExternalId: x}),
        })
        field.store = new DefaultSelectStore(res, (filters) => new TypeOrgsApi(getApiConfiguration()).getTypeOrgs({
            ...filters,
            showOnlyActive: true
        }));
        field!.store.onChanged.push((e) => {
            const parentField = this.store._store.fields.find(x => x.field == "parent")!;
            parentField.store.required = !!e && JurisdictionData.organizationTypes.depsId == e.parentId
            parentField.store.validate();
        });
        field!.store.onChanged.push((e: any) => {
            let clinic = this.store._store.fields.find(x => x.field === 'cliniccategory')
            if (clinic)
                clinic!.store.value = e?.clinicCategory?.nameRus ?? ""
        })
        field.store.required = true;
    }
}

export class PhoneStatusBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new OrganizationPhoneStatusesApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: this.external,
            getInternal: (x) => api.getOrganizationPhoneStatusById({id: x!}),
            getExternal: (x) => api.getOrganizationPhoneStatuses({searchByExternalId: x}),
        })
        field.store = new DefaultSelectStore(res,
            (filters) => new OrganizationPhoneStatusesApi(getApiConfiguration()).getOrganizationPhoneStatuses(filters));
        field.store.required = true;
    }
}

export class PhoneCategoryBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new OrganizationPhoneCategoriesApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: this.external,
            getInternal: (x) => api.getOrganizationPhoneCategoryById({id: x!}),
            getExternal: (x) => api.getOrganizationPhoneCategories({searchByExternalId: x}),
        })
        field.store = new DefaultSelectStore(res,
            (filters) => new OrganizationPhoneCategoriesApi(getApiConfiguration()).getOrganizationPhoneCategories(filters));
        field.store.required = true;
    }
}

export class AdditionalNumberBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store.setValidator((e) => {
            return e?.length! > 50 ? t('errors.lengthMax').replace("lengthMax", "50") : ""
        })
        field.store.required = false;
    }
}

export class CodeBuilder extends BaseFieldBuilder<OrganizationStore> {
    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this.store._store._store._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store.setValidator((value: any) => new RegExp(this.jurisdictionLocalSetting?.itnRegex!).test(value ?? "") ? "" :
            i18n.t('errors.itn').replace("{length}",this.jurisdictionLocalSetting?.intLength!));
        field.store.required = true;
    }
}


export class PhoneBuilder extends BaseFieldBuilder<OrganizationStore> {

    get jurisdictionLocalSetting(): JurisdictionLocalSetting | undefined {
        return this.store._store._store._store.store.store.authorizationStore?.jurisdictionsSettings
    }

    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        field.store.setValidator((e: any) => {
            return new RegExp(this.jurisdictionLocalSetting?.phoneNumberRegex!)
                .test(e ?? "") ? "" : i18n.t('dcrPage.errors.phoneFormat').replace("{length}", this.jurisdictionLocalSetting?.numberLength!)
        })
        field.store.required = true;
    }
}

export class PhoneTypeBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new OrganizationPhoneTypesApi(getApiConfiguration());
        const res = await this.getById({
            id: element.value,
            external: this.external,
            getInternal: (x) => api.getOrganizationPhoneTypeById({id: x}),
            getExternal: (x) => api.getOrganizationPhoneTypes({searchByExternalId: x!})
        })
        field.store = new DefaultSelectStore(res, (filters) => api.getOrganizationPhoneTypes(filters));
        field.store.required = true;
    }
}

export class OriginalIdBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new OrganizationsApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: false,
            getInternal: (x) => api.getOrganizationById({id: x}),
            error: t("dcrPage.fields.person").replace("orgId", element.value)
        });
        field.link = element.value ? `/organizations/${element.value}` : ''
        field.isHide = !element.value;
        field.store = new DefaultSelectStore(res, (filters) => api.getOrganizations({...filters}));
        field.store.nameExp = (x: OrganizationDto) => getOrgName(x);
        field.store.onChanged.push((x) => this.store._store.fields.find(x => x.field == 'originalId')!.link = !!x ? `/organizations/${x.id}` : '')
        field.store.setValidator((e: OrganizationDto) => e?.statusId == OrganizationStatuses.Double ? t("dcrPage.errors.organizationIsDouble").replace("{id}", e.id) : "");
        field.store.isSearchById = true;
        field.store.required = true;
        if (this.store._store.isAz) {
            field.store.onChanged.push((x) => (this.store._store.specification as AzSpecification).handleAzDoubleValComment())
        }
    }
}

export class ClinicCategoryBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const clinicCategoryId = this.store._store.fields.find(x => x.field === 'companytype')?.store.value?.clinicCategoryId
        const api_ = new ClinicCategoriesApi(getApiConfiguration());
        let value = !!clinicCategoryId ? await api_.getClinicCategoryById({id: clinicCategoryId}) : null
        field.isAdditional = true
        field.readonly = true
        field.isHide = false
        field.ignore = true
        field.store.value = value?.nameRus ?? "";
    }
}

export class ArchiveReasonnBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        const api = new OrganizationStatusesApi(getApiConfiguration());
        let res = await this.getById({
            id: element.value,
            external: this.external,
            getInternal: (x) => api.getOrganizationStatusById({id: x}),
            getExternal: (x) => api.getOrganizationStatuses({
                searchByExternalId: x,
                orderBy: 'name_rus',
                order: 'ascending'
            }),
        })
        field.store = new DefaultSelectStore<OrganizationStatusDto>(res, (filters) => api.getOrganizationStatuses({
            ...filters,
            orderBy: 'name_rus',
            order: 'ascending'
        }));
        field.store.nameExp = (x) => x.nameRus ? x.nameRus : t('common.working');
        if (!res) {
            await field.store.pull()
            res = field.store.items.find((x: OrganizationStatusDto) => !x.nameRus.trim());
            field.store.value = res;
        }
        field.store.required = true;
    }
}

export class ParentBuilder extends BaseFieldBuilder<OrganizationStore> {
    async buildSpecific(element: FieldInfoDto, field: RequestField): Promise<void> {
        let value = this.store.dto.dcrStatus === DCRStatusEnum.Create ? element.value :
            (this.store.data.parentId == this.store.data.id ? null : this.store.data.parentId)
        const api = new OrganizationsApi(getApiConfiguration());
        let res = await this.getById({
            id: value,
            external: this.external,
            getInternal: (x) => api.getOrganizationById({id: x}),
            getExternal: (x) => api.getOrganizations({searchByExternalId: x}),
            error: t("dcrPage.errors.orgErrorApi").replace("orgId", element.value)
        })


        let validFn = async (value: OrganizationDto | null): Promise<string> => {
            if (!value) return "";

            if (this.store.data?.id == value.id)
                return t("dcrPage.errors.parentOrgIsThis")
            if (value.statusId === OrganizationStatuses.Double)
                return t("dcrPage.errors.parentOrgDouble")
            else {
                if (this.store.currentType?.id === JurisdictionData.organizationTypes.surgeryId) {
                    if (value.typeOrg.parentId !== JurisdictionData.organizationTypes.depsId || value.typeOrg.id === JurisdictionData.organizationTypes.surgeryId)
                        return t("dcrPage.errors.parentOrgIsNotDeps")
                } else {
                    if (value.typeOrg.parentId === JurisdictionData.organizationTypes.depsId)
                        return t("dcrPage.errors.parentOrgIsDeps")
                            .replace("{orgName}", this.store.currentType?.nameRus ?? "")
                            .replace("{orgType}", value.typeOrg.nameRus)
                }
            }


            return ""
        }
        field.store = new DefaultSelectStore(res,
            (filters) => new OrganizationsApi(getApiConfiguration()).getOrganizations({...filters}));
        field.store.nameExp = (x) => getOrgName(x);
        field.store.onChanged.push((x) => this.store._store.fields.find(x => x.field == element.field)!.link = !!x ? `/organizations/${x.id}` : '')
        field.store.isSearchById = true;
        field.link = element.value ? `/organizations/${element.value}` : ''
        field.store.validatorFuncAsync = validFn
        field.store.required = false;
    }
}
