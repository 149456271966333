import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { AuthorizePermission, EmptyBox } from "../../../../components";
import { DefaultMultiSelect } from "../../../../components/shared/components/DefaultMultiSelect";
import { AllPermissions } from "../../../../core/const";
import { OthersTabsStore } from "../../../../stores/management/organizations/details/others/OthersTabsStore";
import { SelectEntityEnum } from "../../../../stores/management/organizations/services";
import { OrganizationEmailCards } from "../emails/OrganizationEmailCards";
import { OrganizationPhoneCards } from "../phones/OrganizationPhoneCards";
import { OrganizationWebsiteCards } from "../websites/OrganizationWebsiteCards";

interface Props {
    store: OthersTabsStore;
    style?: React.CSSProperties
}

export const OthersTab = observer(({ store, style }: Props) => {
    const { t } = useTranslation();

    const renderSelectEntity = (index: SelectEntityEnum) => {
        switch (index) {
            case SelectEntityEnum.Phone:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.phones.read}>
                        <OrganizationPhoneCards store={store.phoneStore} />
                    </AuthorizePermission>
                )
            case SelectEntityEnum.Email:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.emails.read}>
                        <OrganizationEmailCards store={store.emailStore} />
                    </AuthorizePermission>
                )
            case SelectEntityEnum.Website:
                return (
                    <AuthorizePermission permissions={AllPermissions.organization.websites.read}>
                        <OrganizationWebsiteCards store={store.websiteStore} />
                    </AuthorizePermission>
                )
        }
    };

    return (
        <>
            <DefaultMultiSelect store={store.selectEntity}
                title={t('common.typeEntity')}
                style={{ marginLeft: 24, width: '30%' }}
                nameExp={store.selectEntity.nameExp}
                valueExp={null}
                testid="orgOthersTab-selectEntity"
            />
            {
                store.selectEntity.value.length > 0
                    ? store.selectEntity.value.map((item: SelectEntityEnum) => {
                        return renderSelectEntity(item)
                    })
                    : <EmptyBox />
            }
        </>
    );
});
