import AddBoxIcon from "@mui/icons-material/AddBox";
import { Box, Card, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    AuthorizePermission,
    Cell,
    ConfirmationForm,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    PanelButton,
    Progress
} from "../../../../components";
import { MenuComponent } from "../../../../components/MenuComponent";
import { MenuItemComponent } from "../../../../components/MenuItemComponent";
import { AllPermissions } from "../../../../core/const";
import { DeleteIcon } from "../../../../icons/deleteIcon";
import { Scrollbar } from "../../../../scrollbar";
import { OrganizationNameItemStore } from "../../../../stores/management/organizations/details/names/OrganizationNameItemStore";
import { OrganizationNameTableStore } from "../../../../stores/management/organizations/details/names/OrganizationNameTableStore";
import { OrganizationNameCreator } from "./OrganizationNameCreator";
import { OrganizationNameDetails } from "./OrganizationNameDetails";

interface Props {
    store: OrganizationNameTableStore;
    style?: React.CSSProperties
}

export const OrganizationNameTable = observer(({ store, style }: Props) => {
    const { t } = useTranslation();
    const [openDetails, setOpenDetails] = useState<string | null>(null);

    const handleOpenDetails = (productId: string): void => {
        setOpenDetails((prevValue) => (prevValue === productId ? null : productId));
    };
    return (
        <>
            {
                store.current
                    ? (
                        <ExpertDialog
                            title='Delete'
                            open={store.current.deleteDialogState}
                            onClose={() => store.current ? store.current.deleteDialogState = false : false}
                        >
                            <ConfirmationForm
                                disabled={store.current.processing}
                                onSubmit={async () => await store.current?.delete()}
                                onClose={() => store.current ? store.current.deleteDialogState = false : false}
                            >
                                {t('common.deleteConfirm')} "{store.current.dto.name}"?
                            </ConfirmationForm>
                        </ExpertDialog>
                    )
                    : ''
            }
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <ExpertDialog
                    title={t('create.orgName')}
                    open={store.creator.Open}
                    onClose={() => store.creator.Open = false}
                >
                    <OrganizationNameCreator store={store.creator} />
                </ExpertDialog>
                <Card sx={{ mt: 3 }}>
                    <Scrollbar>
                        <Table sx={{ minWidth: 800 }}>
                            <TableHead>
                                <TableRow>
                                    <HCell width='5%' testid="organizationNameTable-expand"></HCell>
                                    <HCell store={store} testid="organizationNameTable-name">{t('common.name')}</HCell>
                                    <HCell store={store} testid="organizationNameTable-type">{t('common.type')}</HCell>
                                    <HCell store={store} width='7%' testid="organizationNameTable-action">{t('table.action')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {store.items.length > 0 ? (<>
                                    {store.items.map((item: OrganizationNameItemStore) => {
                                        const open = item.dto.id === openDetails;
                                        return (
                                            <Fragment key={item.dto.id}>
                                                <TableRow
                                                    hover
                                                    key={item.dto.id}
                                                    style={{ width: '100%' }}
                                                    onClick={() => store.current = item}
                                                >
                                                    <ExpandButton item={item} isOpen={open} testid="organizationNameTable"
                                                        onClick={() => handleOpenDetails(item.dto.id)} />
                                                    <Cell testid="organizationNameTable-name">
                                                        {item.dto.name}
                                                    </Cell>
                                                    <Cell testid="organizationNameTable-typeName">
                                                        {item.dto?.typeName?.nameRus}
                                                    </Cell>
                                                    <Cell align={'center'} testid="organizationNameTable-action">
                                                        {(store.items.length != 1 && item.dto.typeName?.nameRus.toLowerCase() !== 'синдикативное') &&
                                                            <AuthorizePermission permissions={AllPermissions.organization.names.delete}>
                                                                <MenuComponent>
                                                                    <MenuItemComponent text={t('common.delete')} icon={<DeleteIcon fontSize="small" />}
                                                                        onClick={() => item.deleteDialogState = true} style={{ color: '#D14343' }} />
                                                                </MenuComponent>
                                                            </AuthorizePermission>
                                                        }
                                                    </Cell>
                                                </TableRow>
                                                {open && (
                                                    <ExpandRow key={`${item.dto.id}_`} item={item}>
                                                        <OrganizationNameDetails
                                                            store={item}
                                                        />
                                                    </ExpandRow>)}

                                            </Fragment>
                                        )
                                    })}
                                </>) : <></>}

                            </TableBody>
                        </Table>
                    </Scrollbar>
                </Card>
                <AuthorizePermission permissions={AllPermissions.organization.update}>
                    <div style={{ display: "flex", justifyContent: 'center', width: '100%', flexDirection: "column" }}>
                        <PanelButton onClick={() => store.creator.Open = true}>
                            <AddBoxIcon color='primary' />
                        </PanelButton>

                    </div>
                </AuthorizePermission>
            </Box>
            {!store.items.length && <Box
                sx={{
                    flexGrow: 1,
                    py: 3,
                }}
            >
                <Progress store={store} />
            </Box>
            }
        </>
    );
});
