import { makeAutoObservable } from 'mobx';
import { OrganizationItemStore } from '../../OrganizationItemStore';
import { OrganizationPhoneTableStore } from '../phones/OrganizationPhoneTableStore';
import { OrganizationDto } from '../../../../../services/management';
import { OrganizationEmailTableStore } from '../emails/OrganizationEmailTableStore';
import { OrganizationWebsiteTableStore } from '../website/OrganizationWebsiteTableStore';
import { DefaultMultiSelectStore } from '../../../../../components/shared/DefaultMultiSelectStore';
import { mappingSelectEntityToString, SelectEntityEnum, SelectEntityType } from '../../services';

export class OthersTabsStore {
    readonly store: OrganizationItemStore;
    readonly phoneStore: OrganizationPhoneTableStore;
    readonly emailStore: OrganizationEmailTableStore;
    readonly websiteStore: OrganizationWebsiteTableStore;
    readonly selectEntity: DefaultMultiSelectStore<SelectEntityEnum>;
    allSelectEntity: SelectEntityEnum[] = [1, 2, 3];
    expand: boolean = false;

    constructor(store: OrganizationItemStore, dto: OrganizationDto) {
        this.store = store;
        this.phoneStore = new OrganizationPhoneTableStore(store, dto);
        this.emailStore = new OrganizationEmailTableStore(store, dto);
        this.websiteStore = new OrganizationWebsiteTableStore(store, dto);
        this.selectEntity = new DefaultMultiSelectStore(this.allSelectEntity, Object.values(SelectEntityType), null, null, 'id', (e) => mappingSelectEntityToString(e));

        makeAutoObservable(this);
    }

    updateState() {
        this.selectEntity.value.map(item => {
            if (item === SelectEntityEnum.Phone) {
                this.phoneStore.pull()
            }
            if (item === SelectEntityEnum.Email) {
                this.emailStore.pull()
            }
            if (item === SelectEntityEnum.Website) {
                this.websiteStore.pull()
            }
        })
    }
};
