import { Box, Card, Table, TableBody, TableHead, TableRow, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
    AddButton,
    AuthorizePermission,
    Cell, ConfirmationForm, EmptyBox,
    ExpandButton,
    ExpandRow,
    ExpertDialog,
    HCell,
    Pagination,
    Progress,
    Search
} from '../../../../components';
import { AllPermissions } from "../../../../core/const";
import { StoreProps, callTheProcessingFunc } from "../../../../stores";
import { GroupTableStore } from "../../../../stores/management/users/tabs/Groups/GroupTableStore";
import { GroupCreator } from "./GroupCreator";
import { GroupDetails } from "./GroupDetails";

export const GroupTableTab = observer(({ store }: StoreProps<GroupTableStore>) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{
                flexGrow: 1,
            }}
            >
                <ExpertDialog
                    title='Удаление'
                    open={store.deleteDialogState}
                    onClose={() => store.deleteDialogState = false}
                >
                    <ConfirmationForm
                        disabled={store.processing}
                        onSubmit={async () => await callTheProcessingFunc(store,
                            (e) => store.delete(store.current!.dto!.id!),
                            async (e) => await store.pull())()}
                        onClose={() => store.deleteDialogState = false}
                    >
                        {t('common.deleteConfirm')} "{store.current?.dto.name}"?
                    </ConfirmationForm>
                </ExpertDialog>
                <ExpertDialog
                    title={t('usersPage.addGroup')}
                    open={store.creator.Open}
                    onClose={() => store.creator.Open = false}
                >
                    <GroupCreator store={store.creator} />
                </ExpertDialog>

                <Box sx={{ mb: 2 }}>
                    <Box
                        sx={{
                            mt: 2,
                            mb: 2
                        }}

                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <Search
                            value={store.search.value}
                            onChange={t => store.search.value = t}
                            style={{ width: '87%' }}
                            testid="GroupTableTab"
                        />
                        <AuthorizePermission permissions={AllPermissions.user.create}>
                            <AddButton onClick={() => store.creator.Open = true} />
                        </AuthorizePermission>
                    </Box>

                    <Card sx={{ mb: 8, overflow: 'unset' }}>
                        <Table sx={{ minWidth: 800 }}>
                            <TableHead style={{ position: 'sticky', top: 64, zIndex: 999 }}>
                                <TableRow>
                                    <HCell width='5%' testid="GroupTableTab-expand" />
                                    <HCell orderBy='name' testid="GroupTableTab-name" store={store}>{t('usersPage.groupName')}</HCell>
                                    <HCell orderBy='owner' store={store}
                                        testid="GroupTableTab-owner">{t('usersPage.owner')}</HCell>
                                    <HCell orderBy='participants' store={store} width='25%' styleText={{ marginLeft: 'auto', paddingRight: 36 }}
                                        testid="GroupTableTab-participants">{t('usersPage.participants')}</HCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {store.items.length > 0 && store.items.map((group) => {
                                    return (
                                        <Fragment key={group.dto.id}>
                                            <TableRow
                                                hover
                                                key={group.dto.id}
                                            >
                                                <ExpandButton item={group} isOpen={group.expand} testid="GroupTableTab" />
                                                <Cell testid="GroupTableTab-name">
                                                    <Typography variant="subtitle1" sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    >
                                                        {group.dto.name}
                                                    </Typography>
                                                </Cell>
                                                <Cell testid="GroupTableTab-owner">
                                                    <Typography variant="subtitle2" sx={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    >
                                                        {group.dto.ownerName}
                                                    </Typography>
                                                </Cell>
                                                <Cell align="right" style={{ paddingRight: 60 }}
                                                    testid="GroupTableTab-participants">
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {group.dto.usersCount}
                                                    </Typography>
                                                </Cell>
                                            </TableRow>
                                            {group.expand && (
                                                <ExpandRow key={`${group.dto.id}_`} item={group}>
                                                    <GroupDetails
                                                        store={group}
                                                        style={{
                                                            margin: '0 20px'
                                                        }}
                                                    />
                                                </ExpandRow>)}
                                        </Fragment>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {!store.items.length && <EmptyBox />}
                        {store.items.length > 0 && <Pagination store={store} />}

                        <Progress store={store} />

                    </Card>
                </Box>
            </Box>
        </Box>
    );
});
