import { t } from "i18next";

export enum SelectEntityEnum {
    Phone = 1,
    Email,
    Website,
}

export const SelectEntityType = {
    Phone: 1,
    Email: 2,
    Website: 3
} as const;

export type SelectEntityType = typeof SelectEntityType[keyof typeof SelectEntityType];

export function mappingSelectEntityToString(type: SelectEntityEnum): string {
    switch (type) {
        case SelectEntityEnum.Phone:
            return t('common.phone')
        case SelectEntityEnum.Email:
            return t('common.email')
        case SelectEntityEnum.Website:
            return t('common.websites')
    }
    return ""
}